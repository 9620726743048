import { RegistrationStatus } from 'redux/services/rosterManagementApi';

export enum MemberType {
  Player = 'PLAYER',
  Staff = 'STAFF',
}
type BaseMember = {
  name: string;
  userId: number;
  avatarUrl: string;
  registrationStatus: RegistrationStatus;
};
export type PlayerMember = BaseMember & {
  ageGroup?: number;
  roleId?: never;
  roleName?: never;
  type: MemberType.Player;
};
export type StaffMember = BaseMember & {
  ageGroup?: never;
  roleId: number;
  roleName: string;
  type: MemberType.Staff;
};
export type Member = PlayerMember | StaffMember;

type BaseMemberState = { selected: boolean };
export type PlayerMemberState = BaseMemberState & PlayerMember;
export type StaffMemberState = BaseMemberState & StaffMember;
export type MemberState = PlayerMemberState | StaffMemberState;
