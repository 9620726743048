import {
  Checkbox,
  CloseCircleIcon,
  IconButton,
  Typography,
} from '@la/ds-ui-components';
import { Member, MemberType } from '../types/member';
import * as S from './MemberTile.styles';

export const DEFAULT_AGE_GROUP_TEXT = 'Any';
export const REMOVE_BUTTON_LABEL = 'remove member';

export type MemberTileProps = Member & {
  active?: boolean;
  onRemoveClick?: (member: Member) => void;
  onSelectChange?: (selected: boolean, member: Member) => void;
  selected?: boolean;
  isDisabled: boolean;
};

export function MemberTile(props: Readonly<MemberTileProps>) {
  const {
    active = false,
    name,
    onRemoveClick,
    onSelectChange,
    selected,
    isDisabled,
  } = props;

  const onCheckedChange = (checked: boolean): void => {
    if (onSelectChange) {
      onSelectChange(checked, props);
    }
  };

  return (
    <S.MemberTileBorder
      $active={active}
      $selected={selected}
      $selectable={!!onSelectChange && !isDisabled}
      $disabled={isDisabled}
    >
      <S.MemberTile
        $active={active}
        $selectable={!!onSelectChange && !isDisabled}
        $selected={selected}
        $disabled={isDisabled}
      >
        {!isDisabled ? (
          <Checkbox
            ariaLabel={getMemberTileLabel(props.type, name, {
              ageGroup: props.ageGroup,
              roleName: props.roleName,
            })}
            checked={selected}
            id={getMemberTileId(props.userId, props.type)}
            onCheckedChange={onCheckedChange}
            size="large"
          />
        ) : null}
        <S.MemberTileDetails
          $selectable={!!onSelectChange && !isDisabled}
          $selected={selected}
          onClick={() => onCheckedChange(!selected)}
          $disabled={isDisabled}
        >
          <Typography size="large" variant="ui">
            {name}
          </Typography>
          <Typography size="small" variant="ui">
            {props.type === MemberType.Player
              ? getPlayerAgeGroupText(props.ageGroup)
              : props.roleName}
          </Typography>
        </S.MemberTileDetails>
        {onRemoveClick ? (
          <S.RemoveIcon>
            <IconButton
              ariaLabel={REMOVE_BUTTON_LABEL}
              icon={<CloseCircleIcon fill="var(--red)" variant="filled" />}
              onClick={() => onRemoveClick(props)}
              variant="text"
            />
          </S.RemoveIcon>
        ) : null}
      </S.MemberTile>
    </S.MemberTileBorder>
  );
}

export function getMemberTileId(userId: number, type?: MemberType): string {
  if (type === undefined) {
    return `member-${userId}`;
  }
  return `${type === MemberType.Player ? 'player' : 'staff'}-${userId}`;
}

export function getMemberTileLabel(
  type: MemberType,
  name: string,
  { ageGroup, roleName }: { ageGroup?: number; roleName?: string }
): string {
  const typeText = type === MemberType.Player ? 'player' : 'staff';
  const ageGroupText = ageGroup ? getPlayerAgeGroupText(ageGroup) : '';
  return `${typeText} ${name} ${ageGroupText} ${roleName}`;
}

export function getPlayerAgeGroupText(ageGroup?: number): string {
  return ageGroup ? `${ageGroup}u` : DEFAULT_AGE_GROUP_TEXT;
}
