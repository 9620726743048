import { useState } from 'react';
import 'react-router-dom';
import { Button, DeleteIcon, Snackbar, Typography } from '@la/ds-ui-components';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';
import DeleteRegistrantModal from './DeleteRegistrantModal';
import { EditRegistrantProfileModal } from './EditRegistrantProfileModal';
import * as S from './RosterManagement.styles';

const SNACKBAR_DURATION = 5000;

export type Registrant = {
  avatarUrl: string;
  isPrimaryStaff?: boolean;
  name: string;
  registrationStatus:
    | 'PENDING_INVITE'
    | 'REMOVED'
    | 'SPOT_RESERVED'
    | 'SPOT_PENDING'
    | 'WAITING_LIST';
  role?: string;
  status?: 'complete' | 'incomplete' | 'pending';

  userId: number;
};

export type TableType = 'players' | 'staff';

export type RosterTableData = {
  programId: string;
  registrants: Registrant[];
  tableType: TableType;
  teamId: string;
};

export type TargetRegistrant = {
  memberName: string;
  tableType: TableType;
  userId: number;
};

/* Roster Table */
export default function RosterTable({
  programId,
  registrants,
  tableType,
  teamId,
}: RosterTableData) {
  const [targetRegistrantData, setTargetRegistrantData] =
    useState<TargetRegistrant | null>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [isEditProfileModalVisible, setIsEditProfileModalVisible] =
    useState<boolean>(false);
  const [showRemoveMemberSuccessSnackbar, setShowRemoveMemberSuccessSnackbar] =
    useState<boolean>(false);
  const [showRemoveMemberErrorSnackbar, setShowRemoveMemberErrorSnackbar] =
    useState<boolean>(false);

  const { phoneOnly, tabletPortraitUp, tabletLandscapeUp } = breakpointQueries;
  const isPhone = useMediaQuery(phoneOnly);
  const isTabletPortrait = useMediaQuery(tabletPortraitUp);
  const isTabletLandscape = useMediaQuery(tabletLandscapeUp);

  let registrantNameWeight: 'medium' | 'xl' = 'xl',
    roleLabelSize: 'small' | 'medium' = 'medium',
    statusLabelSize: 'medium' | 'xs' = 'medium',
    statusLabelWeight: 'regular' | 'bold' = 'regular';

  if (isPhone) {
    registrantNameWeight = 'medium';
    roleLabelSize = 'small';
    statusLabelSize = 'xs';
    statusLabelWeight = 'bold';
  } else if (isTabletPortrait) {
    statusLabelSize = 'medium';
    statusLabelWeight = 'regular';
  } else if (isTabletLandscape) {
    statusLabelSize = 'medium';
    statusLabelWeight = 'regular';
  }

  function handleEditButtonClicked(
    targetRegistrantData: TargetRegistrant
  ): void {
    setIsEditProfileModalVisible(true);
    setTargetRegistrantData(targetRegistrantData);
  }

  function handleDeleteButtonClicked(
    targetRegistrantData: TargetRegistrant
  ): void {
    setIsDeleteModalVisible(true);
    setTargetRegistrantData(targetRegistrantData);
  }

  function handleModalClosed(): void {
    setIsDeleteModalVisible(false);
    setIsEditProfileModalVisible(false);
    setTimeout(() => {
      document.body.style.removeProperty('pointer-events');
    }, 0);
  }

  function generateRosterTableRows() {
    return registrants.map(
      (
        {
          avatarUrl,
          isPrimaryStaff,
          name,
          userId,
          registrationStatus,
          role,
          status,
        },
        idx: number
      ) => {
        return (
          <RosterTableRow
            avatarUrl={avatarUrl}
            isPrimaryStaff={isPrimaryStaff}
            name={name}
            userId={userId}
            registrantNameWeight={registrantNameWeight}
            registrationStatus={registrationStatus}
            role={role}
            roleLabelSize={roleLabelSize}
            statusLabelSize={statusLabelSize}
            statusLabelWeight={statusLabelWeight}
            tableType={tableType}
            key={`${tableType}-${idx}`}
            status={status}
            editRegistrantProfile={handleEditButtonClicked}
            deleteRegistrantProfile={handleDeleteButtonClicked}
          />
        );
      }
    );
  }

  return (
    <>
      <S.RosterTable role="table">
        <S.RosterTableHead>
          <S.RosterTableColumn_Avatar>&nbsp;</S.RosterTableColumn_Avatar>
          <S.RosterTableColumn_Name>
            <Typography variant="ui" size="medium" weight="bold">
              Name
            </Typography>
          </S.RosterTableColumn_Name>
          <div>
            <Typography variant="ui" size="medium" weight="bold">
              {tableType === 'players' ? 'Status' : null}
            </Typography>
          </div>
          <S.RosterTableColumn_EditDelete>
            &nbsp;
          </S.RosterTableColumn_EditDelete>
        </S.RosterTableHead>
        <S.RosterTableBody>{generateRosterTableRows()}</S.RosterTableBody>
      </S.RosterTable>
      {targetRegistrantData && isEditProfileModalVisible ? (
        <EditRegistrantProfileModal
          {...targetRegistrantData}
          closeModal={handleModalClosed}
        />
      ) : null}
      {targetRegistrantData && isDeleteModalVisible ? (
        <DeleteRegistrantModal
          {...targetRegistrantData}
          closeModal={handleModalClosed}
          programId={programId}
          teamId={teamId}
          onRemoveSuccess={() => setShowRemoveMemberSuccessSnackbar(true)}
          onRemoveError={() => setShowRemoveMemberErrorSnackbar(true)}
        />
      ) : null}
      <Snackbar
        description={`${targetRegistrantData?.memberName} was successfully removed from your roster.`}
        duration={SNACKBAR_DURATION}
        open={showRemoveMemberSuccessSnackbar}
        onOpenChange={setShowRemoveMemberSuccessSnackbar}
      />
      <Snackbar
        description={`We were unable to remove ${targetRegistrantData?.memberName} from your roster. Please try again.`}
        duration={SNACKBAR_DURATION}
        open={showRemoveMemberErrorSnackbar}
        onOpenChange={setShowRemoveMemberErrorSnackbar}
        variant="error"
      />
    </>
  );
}
/* */

/* Roster Table Row */
export function RosterTableRow({
  avatarUrl,
  deleteRegistrantProfile,
  editRegistrantProfile,
  isPrimaryStaff,
  name,
  registrantNameWeight,
  registrationStatus,
  role,
  roleLabelSize,
  statusLabelSize,
  statusLabelWeight,
  tableType,
  status,
  userId,
}: {
  avatarUrl: string | null;
  deleteRegistrantProfile: (targetRegistrantData: TargetRegistrant) => void;
  editRegistrantProfile: (targetRegistrantData: TargetRegistrant) => void;
  isPrimaryStaff?: boolean;
  name: string;
  registrantNameWeight: 'medium' | 'xl';
  registrationStatus: Registrant['registrationStatus'];
  role?: string;
  roleLabelSize: 'small' | 'medium';
  status?: 'complete' | 'incomplete' | 'pending';
  statusLabelSize: 'xs' | 'medium';
  statusLabelWeight: 'regular' | 'bold';
  tableType: TableType;
  userId: number;
}) {
  function onDeleteProfileClicked(): void {
    deleteRegistrantProfile({
      memberName: name,
      tableType: tableType,
      userId: userId,
    });
  }
  function onEditProfileClicked(): void {
    editRegistrantProfile({
      memberName: name,
      tableType: tableType,
      userId: userId,
    });
  }

  let registrationStatusText;
  if (registrationStatus === 'SPOT_RESERVED') {
    registrationStatusText = 'Complete';
  } else if (registrationStatus === 'PENDING_INVITE') {
    registrationStatusText = 'Pending';
  } else {
    registrationStatusText = 'Started';
  }

  return (
    <S.RosterTableRow>
      <S.RosterTableColumn_Avatar>
        <RosterAvatar avatarUrl={avatarUrl} name={name} />
      </S.RosterTableColumn_Avatar>
      <S.RosterTableColumn_Name>
        <Typography variant="ui" size={registrantNameWeight} weight="bold">
          {name}
        </Typography>
        {role ? (
          <Typography variant="ui" size={roleLabelSize} weight="regular">
            {role}
          </Typography>
        ) : null}
      </S.RosterTableColumn_Name>
      <S.RosterStatusBadge $registrationStatus={registrationStatus}>
        <Typography
          variant="ui"
          size={statusLabelSize}
          weight={statusLabelWeight}
        >
          {registrationStatusText}
        </Typography>
      </S.RosterStatusBadge>
      <S.RosterTableColumn_EditDelete>
        {tableType === 'players' && status !== 'pending' ? (
          <Button
            variant="outline"
            size="medium"
            onClick={onEditProfileClicked}
          >
            Edit
          </Button>
        ) : null}
        {registrationStatus !== 'PENDING_INVITE' ? (
          <S.DeleteButton
            aria-label="delete registrant"
            onClick={onDeleteProfileClicked}
            data-testid={`delete-button-${userId}`}
            disabled={isPrimaryStaff}
          >
            <DeleteIcon variant="bold" size="medium" />
          </S.DeleteButton>
        ) : null}
      </S.RosterTableColumn_EditDelete>
    </S.RosterTableRow>
  );
}
/*  */

/* Roster Avatar */
export function RosterAvatar({
  avatarUrl,
  name,
}: {
  avatarUrl: string | null;
  name: string;
}) {
  const [shouldUseImage, setShouldUseImage] = useState<boolean>(!!avatarUrl);
  function extractInitials(name: string) {
    const arr = name.split(' ');
    const first = arr[0].slice(0, 1);
    const last = arr.length > 1 ? arr[arr.length - 1].slice(0, 1) : '';
    return first + last;
  }
  return (
    <S.RosterAvatar>
      {avatarUrl && shouldUseImage ? (
        <img
          src={avatarUrl}
          alt={extractInitials(name)}
          height="56"
          onError={() => setShouldUseImage(false)}
        />
      ) : (
        <Typography variant="headline" size="small">
          {extractInitials(name)}
        </Typography>
      )}
    </S.RosterAvatar>
  );
}
/*  */
