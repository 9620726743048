"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIncompleteRequiredFieldsTeams = exports.hasIncompleteRequiredFields = void 0;
var hasIncompleteRequiredFields = function (formFields, team) {
    var requiredFields = formFields.filter(function (field) { return field.usageLevel === 'REQUIRED'; });
    if (!requiredFields.length) {
        return false;
    }
    return requiredFields.some(function (field) {
        var _a;
        var teamFormField = (_a = team.formFields) === null || _a === void 0 ? void 0 : _a.find(function (_a) {
            var formFieldId = _a.formFieldId;
            return formFieldId === field.propertyDefinitionId;
        });
        if (!teamFormField || !teamFormField.values) {
            return true;
        }
        if (field.type === 'MULTIPLE_CHECKBOXES' || field.type === 'PICK_LIST') {
            var value_1 = teamFormField.values[0];
            return !field.items.find(function (item) { return item.itemId.toString() === value_1; });
        }
        return !teamFormField.values[0];
    });
};
exports.hasIncompleteRequiredFields = hasIncompleteRequiredFields;
var getIncompleteRequiredFieldsTeams = function (formFields, teams) {
    return teams.filter(function (team) { return (0, exports.hasIncompleteRequiredFields)(formFields, team); });
};
exports.getIncompleteRequiredFieldsTeams = getIncompleteRequiredFieldsTeams;
