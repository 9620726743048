import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider, BaseColors } from '@la/ds-ui-components';
import { AccountInfoContext } from 'lib/apis/getAccountInfo';
import useGetRoutePaths from 'lib/hooks/useGetRoutePaths';
import { parseHTML } from 'lib/utils/analytics';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { setSiteInfo } from 'redux/coreSlice';
import { useGetAccountInfoQuery } from 'redux/services/accountApi';
import {
  useGetSiteInfoBySubdomainQuery,
  useGetSiteThemeColorsQuery,
  useGetSiteTrackingSettingsQuery,
} from 'redux/services/siteInfo';
import { useAppDispatch } from 'redux/store';
import './App.css';
import { CheckoutInfoProvider } from './lib/context/CheckoutInfoContext';

const fallbackThemeColors: BaseColors = {
  primary: '#37474F',
  secondary: '#007A00',
};

export default function App() {
  /**
   * NOTE: If the site is being hosted by and hosting deploy-previews on
   * Netlify the subdomain (containing 'la-member-portal') will not return
   * valid site info. In these cases a substitute subdomain of 'svssc' is
   * being provided instead. This is only needed for deploy-previews on
   * Netlify at this time. -DC 03/22/2023
   */
  const currentSubdomain = getLAHostnameParts().subdomain;
  const subDomain =
    currentSubdomain.indexOf('la-member-portal') > -1
      ? 'svssc'
      : currentSubdomain;

  const {
    data: siteInfoData /*,
    error: siteInfoError,
    isLoading: isSiteInfoLoading, */,
  } = useGetSiteInfoBySubdomainQuery(subDomain);
  const { data: accountInfo } = useGetAccountInfoQuery(
    { context: AccountInfoContext.Admin, siteId: siteInfoData?.siteId ?? '' },
    {
      skip: !siteInfoData?.siteId,
    }
  );

  const { data: siteTrackingSettings } = useGetSiteTrackingSettingsQuery(
    { siteId: siteInfoData?.siteId ? parseInt(siteInfoData.siteId) : -1 },
    { skip: !siteInfoData?.siteId }
  );

  const {
    data: themeColorsData /*,
    error: themeColorsError,
    isLoading: isThemeColorsLoading, */,
    isFetching: isThemeColorsFetching,
  } = useGetSiteThemeColorsQuery(siteInfoData?.siteId, {
    skip: !siteInfoData?.siteId,
  });
  const dispatch = useAppDispatch();

  const [themeColors, setThemeColors] = useState<BaseColors>();

  const routePaths = useGetRoutePaths();

  useEffect(() => {
    const { hostname, pathname } = window.location;
    const newScript = document.createElement('script');
    newScript.setAttribute('type', 'text/javascript');
    const skip = pathname.match(/^\/console\/sites\/\d+\/teams/g) != null;
    if (accountInfo && !skip) {
      const inlineScript = document.createTextNode(`
        window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
        heap.load("${hostname.indexOf('leagueapps.com') > -1 ? '3732699383' : '2991539573'}");
        heap.addUserProperties({"orgAccountId" : "${accountInfo.user.id ?? ''}"});
        heap.addUserProperties({"siteId" : "${accountInfo.site.id}"});
        heap.addUserProperties({"orgAccountName" : "${accountInfo.site.name}"});
        heap.addUserProperties({"siteName" : "${accountInfo.site.name}"});
        heap.addUserProperties({"orgAccountStatus" : "${accountInfo.orgAccount.status?.toUpperCase() ?? ''}"});
        heap.addUserProperties({"siteMode" : "${accountInfo.site.mode?.toUpperCase() ?? ''}"}); 
        heap.addUserProperties({"userEmail" : "${accountInfo.user.email}"});
        heap.addUserProperties({"userName" : "${accountInfo.user.firstName} ${accountInfo.user.lastName}"});
        heap.addUserProperties({"orgAccountRole" : "${accountInfo.user.orgAccountRole.toUpperCase()}"});
      `);
      newScript.appendChild(inlineScript);
      document.head.appendChild(newScript);

      return () => {
        document.head.removeChild(newScript);
      };
    }
  }, [accountInfo]);

  useEffect(() => {
    if (themeColorsData) {
      setThemeColors({
        primary: themeColorsData.theme.primaryColor,
        secondary: themeColorsData.theme.secondaryColor,
      });
    }
  }, [themeColorsData]);

  useEffect(() => {
    if (siteTrackingSettings) {
      if (siteTrackingSettings.pixelTrackingHeader) {
        document.head.appendChild(
          parseHTML(siteTrackingSettings.pixelTrackingHeader)
        );
      }

      if (siteTrackingSettings.pixelTrackingBody) {
        document.body.appendChild(
          parseHTML(siteTrackingSettings.pixelTrackingBody)
        );
      }
    }
  }, [siteTrackingSettings]);

  useEffect(() => {
    function updateStoreWithSiteInfo(
      logoHref: string,
      logoImageSrc: string,
      siteId: string,
      siteName: string
    ) {
      dispatch(
        setSiteInfo({
          logoHref: logoHref,
          logoImageSrc: logoImageSrc,
          siteId: siteId,
          siteName: siteName,
        })
      );
    }

    if (siteInfoData) {
      const { logoHref, logoImageSrc, siteId, siteName } = siteInfoData;
      updateStoreWithSiteInfo(logoHref, logoImageSrc, siteId, siteName);
    }
  }, [dispatch, siteInfoData]);

  return siteInfoData && !isThemeColorsFetching ? (
    <ThemeProvider baseColors={themeColors || fallbackThemeColors}>
      <CheckoutInfoProvider>
        <RouterProvider router={routePaths} />
      </CheckoutInfoProvider>
    </ThemeProvider>
  ) : null;
}
