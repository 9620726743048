"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateTeamForm = exports.extractCustomFieldValue = exports.REP_EMAIL_VALID_ERROR = exports.FIELD_REQUIRED_ERROR = exports.API_ERROR_MESSAGE = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_hook_form_1 = require("react-hook-form");
var yup_1 = require("@hookform/resolvers/yup");
var Tooltip = require("@radix-ui/react-tooltip");
var yup = require("yup");
var ds_ui_components_1 = require("@la/ds-ui-components");
var services_1 = require("@la/services");
var services_2 = require("@la/services");
var utilities_1 = require("@la/utilities");
var ErrorCard_1 = require("../../../../../components/ErrorCard/ErrorCard");
var hooks_1 = require("../../../../../lib/hooks");
var breakpoints_1 = require("../../../../../lib/media-queries/breakpoints");
var TeamRegistrationContext_1 = require("../../../TeamRegistration/TeamRegistrationContext");
var CustomFieldsForm_1 = require("../CustomFieldsForm/CustomFieldsForm");
var ageGroup_1 = require("./utils/ageGroup");
var S = require("./CreateTeamForm.styles");
exports.API_ERROR_MESSAGE = 'There was an error creating your team. Please try creating again in a few seconds.';
exports.FIELD_REQUIRED_ERROR = 'This field is required.';
exports.REP_EMAIL_VALID_ERROR = 'The team rep email must be in a valid email format.';
var CUSTOM_TEAM_FIELDS_NAME = 'customTeamFields';
var extractCustomFieldValue = function (customFields, existingFields) {
    return customFields.map(function (customField) {
        var _a, _b;
        var existingField = existingFields === null || existingFields === void 0 ? void 0 : existingFields.find(function (filledField) {
            return filledField.formFieldId === customField.propertyDefinitionId;
        });
        var value;
        if (existingField) {
            if (customField.type === 'FILE_UPLOAD') {
                value = {
                    name: existingField.values[0],
                    uuid: existingField.values[1],
                };
            }
            else if (customField.type === 'MULTIPLE_CHECKBOXES') {
                value = existingField.values.map(function (v) {
                    var _a, _b;
                    return (_b = (_a = customField.items) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.itemId === parseInt(v); })) === null || _b === void 0 ? void 0 : _b.itemId;
                });
            }
            else if (customField.type === 'PICK_LIST') {
                value = (_b = (_a = customField.items) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.itemId === parseInt(existingField.values[0]); })) === null || _b === void 0 ? void 0 : _b.itemId;
            }
            else {
                value = existingField.values[0];
            }
        }
        return __assign(__assign({}, customField), { value: value });
    });
};
exports.extractCustomFieldValue = extractCustomFieldValue;
exports.CreateTeamForm = (0, react_1.forwardRef)(function (_a, ref) {
    var _b;
    var _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var id = _a.id, defaultValues = _a.defaultValues, maxDivisionAgeGroup = _a.maxDivisionAgeGroup, _o = _a.hasSubmitError, hasSubmitError = _o === void 0 ? false : _o, onChange = _a.onChange, onSubmit = _a.onSubmit, customFields = _a.customFields, existingFields = _a.existingFields, displayAgeGroupOptions = _a.displayAgeGroupOptions;
    var isMobile = (0, hooks_1.useMediaQuery)(breakpoints_1.breakpointQueries.phoneOnly);
    var registrationContext = (0, react_1.useContext)(TeamRegistrationContext_1.TeamRegistrationContext);
    var siteId = (0, utilities_1.getSiteIdFromURL)();
    var userName = (0, services_2.useAppSelector)(services_2.getUserName);
    var userEmail = (0, services_2.useAppSelector)(services_2.getUserEmail);
    var _p = (0, services_1.useGetCountriesAndAdministrativeDivisionsQuery)().data, countriesAndAdministrativeDivisions = _p === void 0 ? {
        countries: {},
        provincesCAN: {},
        statesUSA: {},
    } : _p;
    var countries = countriesAndAdministrativeDivisions.countries, provincesCAN = countriesAndAdministrativeDivisions.provincesCAN, statesUSA = countriesAndAdministrativeDivisions.statesUSA;
    var _q = (0, services_1.useGetTeamRepLabelQuery)({
        siteId: siteId.toString(),
    }).data, teamRepLabel = _q === void 0 ? '' : _q;
    var _r = (0, react_1.useState)(), isRep = _r[0], setIsRep = _r[1];
    var ageGroupOptions = (0, ageGroup_1.createAgeGroupOptions)(maxDivisionAgeGroup);
    var countryOptions = (0, utilities_1.getSelectOptions)(countries);
    var provinceCANOptions = (0, utilities_1.getSelectOptions)(provincesCAN);
    var stateUSAOptions = (0, utilities_1.getSelectOptions)(statesUSA);
    var createTeamFormValidation = yup
        .object({
        teamName: yup.string().trim().required(exports.FIELD_REQUIRED_ERROR),
        organization: yup.string().trim().required(exports.FIELD_REQUIRED_ERROR),
        ageGroup: yup
            .number()
            .nullable()
            .transform(function (_, val) { return (val === 0 || val ? Number(val) : null); })
            .test('required-age-group', exports.FIELD_REQUIRED_ERROR, function (ageGroup) { return !ageGroup || ageGroup !== -1; }),
        country: yup.string().trim().required(exports.FIELD_REQUIRED_ERROR),
        city: yup.string().trim().required(exports.FIELD_REQUIRED_ERROR),
        state: yup
            .string()
            .when('country', function (_a, schema) {
            var country = _a[0];
            if (country === 'USA') {
                return schema.required(exports.FIELD_REQUIRED_ERROR);
            }
            return schema.optional();
        }),
        province: yup
            .string()
            .when('country', function (_a, schema) {
            var country = _a[0];
            if (country === 'CAN') {
                return schema.required(exports.FIELD_REQUIRED_ERROR);
            }
            return schema.optional();
        }),
        repName: yup.string().trim().required(exports.FIELD_REQUIRED_ERROR),
        repEmail: yup
            .string()
            .trim()
            .required(exports.FIELD_REQUIRED_ERROR)
            .test('valid-email', exports.REP_EMAIL_VALID_ERROR, utilities_1.isValidEmail),
        repPhoneNumber: yup.string().trim().required(exports.FIELD_REQUIRED_ERROR),
    })
        .concat((0, CustomFieldsForm_1.createValidationSchema)(CUSTOM_TEAM_FIELDS_NAME));
    var methods = (0, react_hook_form_1.useForm)({
        defaultValues: __assign(__assign({}, defaultValues), (_b = {}, _b[CUSTOM_TEAM_FIELDS_NAME] = (0, exports.extractCustomFieldValue)(customFields, existingFields), _b)),
        resolver: (0, yup_1.yupResolver)(createTeamFormValidation),
    });
    var _s = methods.formState, errors = _s.errors, isSubmitting = _s.isSubmitting, submitCount = _s.submitCount, getValues = methods.getValues, handleSubmit = methods.handleSubmit, register = methods.register, setValue = methods.setValue, watch = methods.watch;
    var ageGroup = watch('ageGroup');
    var country = watch('country');
    var toggleIsRep = function (checked) {
        if (checked) {
            // It is safe to assume that we are in MC if we have a defined payer
            if (registrationContext === null || registrationContext === void 0 ? void 0 : registrationContext.state.payer) {
                var payer = registrationContext.state.payer;
                var repName = "".concat(payer.firstName, " ").concat(payer.lastName);
                var repEmail = payer.email;
                setValue('repName', repName);
                setValue('repEmail', repEmail);
            }
            else {
                setValue('repName', userName);
                setValue('repEmail', userEmail);
            }
        }
        setIsRep(checked);
    };
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, __assign({}, methods, { children: (0, jsx_runtime_1.jsxs)(ds_ui_components_1.Form, __assign({ id: id, noValidate: true, onChange: function () {
                if (onChange) {
                    onChange(getValues());
                }
            }, onSubmit: handleSubmit(function (values) {
                if (!isSubmitting) {
                    onSubmit(values);
                }
            }), ref: ref }, { children: [hasSubmitError ? (0, jsx_runtime_1.jsx)(ErrorCard_1.ErrorCard, { message: exports.API_ERROR_MESSAGE }) : null, (0, jsx_runtime_1.jsx)(ds_ui_components_1.Fieldset, __assign({ hasGutters: false, legend: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Legend, { children: "Provide more information on the new team you are creating" }) }, { children: (0, jsx_runtime_1.jsxs)(ds_ui_components_1.InputGroup, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.TextInput, __assign({}, register('teamName'), { errorMessage: (_c = errors.teamName) === null || _c === void 0 ? void 0 : _c.message, hasError: !!errors.teamName, id: "create-team-team-name", label: "Team name", required: true, value: watch('teamName') })), (0, jsx_runtime_1.jsxs)(S.HorizontalInputGroup, __assign({ "$isMobile": isMobile }, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.TextInput, __assign({}, register('organization'), { errorMessage: (_d = errors.organization) === null || _d === void 0 ? void 0 : _d.message, hasError: !!errors.organization, id: "create-team-organization", label: "Organization", required: true, value: watch('organization') })), (0, jsx_runtime_1.jsx)(S.SelectTemporaryPositionContainer, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Select, __assign({}, register('ageGroup'), { "data-testid": "create-team-age-group", errorMessage: (_e = errors.ageGroup) === null || _e === void 0 ? void 0 : _e.message, hasError: !!errors.ageGroup, id: "create-team-age-group", label: "Age group", options: ageGroupOptions, onChange: function (ageGroup) {
                                                if (ageGroup) {
                                                    setValue('ageGroup', Number(ageGroup), {
                                                        shouldValidate: submitCount > 0,
                                                    });
                                                }
                                                else {
                                                    /**
                                                     * If placeholder option gets selected, using Number() will
                                                     * cause the value to be 0 because the value is undefined, which we
                                                     * do not want because ageGroup being 0 sets the Select to
                                                     * be read-only. Setting to -1 allows us to conditionally set the
                                                     * value to undefined if needed.
                                                     */
                                                    setValue('ageGroup', -1);
                                                }
                                            }, placeholder: "Select age group", required: true, value: ageGroup >= 0 ? ageGroup.toString() : undefined })) })] })), (0, jsx_runtime_1.jsx)(S.SelectTemporaryPositionContainer, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Select, __assign({}, register('country'), { hasError: !!errors.country, errorMessage: (_f = errors.country) === null || _f === void 0 ? void 0 : _f.message, id: "create-team-country", label: "Country", onChange: function (country) {
                                        return setValue('country', country, {
                                            shouldValidate: submitCount > 0,
                                        });
                                    }, options: countryOptions, placeholder: "Select country", required: true, value: watch('country'), testId: "create-team-country-trigger", valueTestId: "create-team-country-value" })) }), (0, jsx_runtime_1.jsxs)(S.HorizontalInputGroup, __assign({ "$isMobile": isMobile }, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.TextInput, __assign({}, register('city'), { hasError: !!errors.city, errorMessage: (_g = errors.city) === null || _g === void 0 ? void 0 : _g.message, id: "create-team-city", label: "City", required: true, value: watch('city') })), (0, jsx_runtime_1.jsxs)(S.SelectTemporaryPositionContainer, { children: [country === 'USA' ? ((0, jsx_runtime_1.jsx)(ds_ui_components_1.Select, __assign({}, register('state'), { errorMessage: (_h = errors.state) === null || _h === void 0 ? void 0 : _h.message, hasError: !!errors.state, id: "create-team-state", label: "State", options: stateUSAOptions, onChange: function (state) {
                                                    return setValue('state', state, {
                                                        shouldValidate: submitCount > 0,
                                                    });
                                                }, placeholder: "Select state", required: true, value: watch('state'), testId: "create-team-state-trigger", valueTestId: "create-team-state-value" }))) : null, country === 'CAN' ? ((0, jsx_runtime_1.jsx)(ds_ui_components_1.Select, __assign({}, register('province'), { errorMessage: (_j = errors.province) === null || _j === void 0 ? void 0 : _j.message, hasError: !!errors.province, id: "create-team-province", label: "Province", options: provinceCANOptions, onChange: function (province) {
                                                    return setValue('province', province, {
                                                        shouldValidate: submitCount > 0,
                                                    });
                                                }, placeholder: "Select province", required: true, value: watch('province'), testId: "create-team-province-trigger", valueTestId: "create-team-province-value" }))) : null] })] })), customFields.length ? ((0, jsx_runtime_1.jsx)(CustomFieldsForm_1.CustomFields, { inputSize: "large", columns: 2, name: CUSTOM_TEAM_FIELDS_NAME })) : null] }) })), (0, jsx_runtime_1.jsx)(ds_ui_components_1.Fieldset, __assign({ hasGutters: false, legend: (0, jsx_runtime_1.jsxs)(ds_ui_components_1.Legend, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ size: "large", variant: "ui", weight: "bold" }, { children: (0, jsx_runtime_1.jsxs)(S.Title, { children: [teamRepLabel, " info", ' ', (0, jsx_runtime_1.jsx)(TeamRepInfo, { teamRepLabel: teamRepLabel })] }) })), "The ", teamRepLabel, " is responsible for managing team info, waivers, rosters and more."] }) }, { children: (0, jsx_runtime_1.jsxs)(ds_ui_components_1.InputGroup, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.TextInput, __assign({}, register('repName'), { errorMessage: (_k = errors.repName) === null || _k === void 0 ? void 0 : _k.message, hasError: !!errors.repName, id: "create-team-rep-name", label: "".concat(teamRepLabel, " name"), readOnly: isRep, required: true, value: watch('repName') })), (0, jsx_runtime_1.jsxs)(S.HorizontalInputGroup, __assign({ "$isMobile": isMobile }, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.TextInput, __assign({}, register('repPhoneNumber'), (0, utilities_1.getMaskPhoneNumberHandlers)(function (value) {
                                        return setValue('repPhoneNumber', value);
                                    }), { errorMessage: (_l = errors.repPhoneNumber) === null || _l === void 0 ? void 0 : _l.message, hasError: !!errors.repPhoneNumber, id: "create-team-rep-phone-number", label: "".concat(teamRepLabel, " phone number"), maxLength: utilities_1.PHONE_NUMBER_MAX_LENGTH, type: "tel", required: true, showCharacterCounter: false, value: watch('repPhoneNumber') })), (0, jsx_runtime_1.jsx)(ds_ui_components_1.TextInput, __assign({}, register('repEmail'), { errorMessage: (_m = errors.repEmail) === null || _m === void 0 ? void 0 : _m.message, hasError: !!errors.repEmail, id: "create-team-rep-email", label: "".concat(teamRepLabel, " email"), readOnly: isRep, required: true, value: watch('repEmail') }))] })), (0, jsx_runtime_1.jsx)(ds_ui_components_1.Checkbox, { ariaLabel: !(registrationContext === null || registrationContext === void 0 ? void 0 : registrationContext.state.isMC)
                                    ? "Make me the ".concat(teamRepLabel)
                                    : "Assign payer as ".concat(teamRepLabel), id: "create-team-rep-make-self-rep", checked: isRep, label: !(registrationContext === null || registrationContext === void 0 ? void 0 : registrationContext.state.isMC)
                                    ? "Make me the ".concat(teamRepLabel)
                                    : "Assign payer as ".concat(teamRepLabel), onCheckedChange: toggleIsRep, size: "large" })] }) }))] })) })));
});
/**
 * TODO: Replace with Tooltip component once available. Separated
 * out into its own component to keep the main component less cluttered.
 */
function TeamRepInfo(_a) {
    var teamRepLabel = _a.teamRepLabel;
    return ((0, jsx_runtime_1.jsx)(Tooltip.Provider, { children: (0, jsx_runtime_1.jsxs)(Tooltip.Root, __assign({ delayDuration: 0 }, { children: [(0, jsx_runtime_1.jsx)(Tooltip.Trigger, __assign({ asChild: true }, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.QuestionIcon, {}) })), (0, jsx_runtime_1.jsx)(Tooltip.Portal, { children: (0, jsx_runtime_1.jsxs)(S.TeamRepInfo, __assign({ sideOffset: 5 }, { children: ["If your team does not yet have a ", teamRepLabel, ", assign yourself and you can transfer ownership later.", (0, jsx_runtime_1.jsx)(Tooltip.Arrow, { className: "TooltipArrow" })] })) })] })) }));
}
