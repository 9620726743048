"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscardTeamButtonContainer = exports.AddedTeamName = exports.AddedTeamNameContainer = exports.ActionIconsContainer = exports.AddedTeam = exports.AddedTeamsContainer = exports.AddTeamsButtonContainer = exports.SelectContainer = exports.SelectSection = exports.TeamSelectionContainer = void 0;
var styled_components_1 = require("styled-components");
var ds_ui_components_1 = require("@la/ds-ui-components");
exports.TeamSelectionContainer = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 16px 24px;\n  background-color: var(--blue-grey-35);\n  width: 100%;\n"], ["\n  padding: 16px 24px;\n  background-color: var(--blue-grey-35);\n  width: 100%;\n"])));
exports.SelectSection = styled_components_1.default.div(function (_a) {
    var _b;
    var theme = _a.theme;
    return _b = {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            marginTop: 16
        },
        _b[theme.mediaQueries.mobile] = {
            flexDirection: 'row',
        },
        _b;
});
exports.SelectContainer = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n  width: 100%;\n"], ["\n  flex: 1;\n  width: 100%;\n"])));
exports.AddTeamsButtonContainer = styled_components_1.default.div({
    '& button': {
        backgroundColor: 'white',
    },
});
exports.AddedTeamsContainer = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 16px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"], ["\n  margin-top: 16px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"])));
exports.AddedTeam = styled_components_1.default.div(function (_a) {
    var theme = _a.theme, $isLast = _a.$isLast;
    var borderColor = theme.semantic
        ? theme.semantic.primary[100]
        : theme.foundational.blueGrey[100];
    return {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.foundational.white,
        minHeight: 48,
        padding: 12,
        borderBottom: $isLast ? 'none' : "1px solid ".concat(borderColor),
    };
});
exports.ActionIconsContainer = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-top: 4px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-top: 4px;\n"])));
exports.AddedTeamNameContainer = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  overflow-wrap: break-word;\n  flex-wrap: wrap;\n"], ["\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  overflow-wrap: break-word;\n  flex-wrap: wrap;\n"])));
exports.AddedTeamName = (0, styled_components_1.default)(ds_ui_components_1.Typography)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  word-break: break-word;\n  flex-grow: 1;\n"], ["\n  word-break: break-word;\n  flex-grow: 1;\n"])));
exports.DiscardTeamButtonContainer = styled_components_1.default.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  cursor: ", ";\n"], ["\n  cursor: ", ";\n"])), function (_a) {
    var $disabled = _a.$disabled;
    return ($disabled ? 'not-allowed' : 'pointer');
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
