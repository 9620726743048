export type RegistrationAnalyticsData = {
  programFullName?: string;
  programId?: number;
  programSport?: string;
  programType?: string;
  registrationUserId?: string;
  registrationId?: string;
};

/**
 * Injects the registration analytics data into the document at the beginning of the
 * <body> tag so that any scripts that are injected after will have access to these variables.
 * @param data registration-related data
 */
export const injectRegistrationAnalyticsData = (
  data: RegistrationAnalyticsData
): void => {
  const registrationAnalyticsData = parseHTML(`
    <div itemscope="program-${data.programId ?? ''}" itemtype="http://www.leagueapps.com/registration">
      <span id="programFullName" itemprop="programFullName" hidden="true">${data.programFullName ?? ''}</span>
      <span id="programId" itemprop="programId" hidden="true">${data.programId ?? ''}</span>
      <span id="programType" itemprop="programType" hidden="true">${data.programType?.toUpperCase() ?? ''}</span>
      <span id="programSport" itemprop="programSport" hidden="true">${data.programSport ?? ''}</span>
      <span id="registrationUserId" itemprop="registrationUserId" hidden="true">${data.registrationUserId ?? ''}</span>
      <span id="registrationId" itemprop="registrationId" hidden="true">${data.registrationId ?? ''}</span>
    </div>
  `);

  document.body.prepend(registrationAnalyticsData);
};

/**
 * Parses an HTML string for rendering inside the HTMl docuemnt.
 */
export const parseHTML = Range.prototype.createContextualFragment.bind(
  document.createRange()
);
