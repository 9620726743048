import { CSSObject } from 'styled-components';

/**
 * Return the cursor property depending on the state of drag item.
 * @param selected Whether or not the drag item is selected.
 * @param selectable Whether or not the drag item can be selected.
 * @returns The value for the `cursor` CSS property.
 */
export const getCursorProperty = (
  selected: boolean | undefined,
  selectable: boolean | undefined,
  disabled?: boolean | undefined
): CSSObject['cursor'] => {
  if (disabled) {
    return 'not-allowed';
  }
  if (selected) {
    return 'move';
  }
  if (selectable) {
    return 'pointer';
  }
  return 'auto';
};
