import { useEffect, useMemo, useState } from 'react';
import { TeamRoster } from 'redux/services/rosterManagementApi';
import {
  MemberState,
  MemberType,
  type PlayerMemberState,
  type StaffMemberState,
} from '../RolloverDropzone/RolloverTeam/types/member';

export type RolloverTeamState = {
  players: PlayerMemberState[];
  staff: StaffMemberState[];
};

export type UseGetMembersStateArgs = {
  teamRoster: TeamRoster;
};

export type UseGetMembersStatePayload = {
  membersState: RolloverTeamState;
  setMembersState: React.Dispatch<React.SetStateAction<RolloverTeamState>>;
  selectedMembers: MemberState[];
};

function getMemberState(teamRoster: TeamRoster): RolloverTeamState {
  const playersState: PlayerMemberState[] = teamRoster.players.map(
    (player) => ({
      ...player,
      ageGroup: 8,
      selected: false,
      type: MemberType.Player,
    })
  );
  const staffState: StaffMemberState[] = teamRoster.staff.map((staff) => ({
    ...staff,
    roleId: 1,
    roleName: staff.role ?? '',
    selected: false,
    type: MemberType.Staff,
  }));

  return { players: playersState, staff: staffState };
}
export const useGetMembersState = ({
  teamRoster,
}: UseGetMembersStateArgs): UseGetMembersStatePayload => {
  const [membersState, setMembersState] = useState<RolloverTeamState>(
    getMemberState(teamRoster)
  );

  useEffect(() => {
    setMembersState(getMemberState(teamRoster));
  }, [teamRoster]);

  const selectedMembers: MemberState[] = useMemo(() => {
    return Object.values(membersState)
      .flat()
      .filter((member) => member.selected);
  }, [membersState]);

  const payload: UseGetMembersStatePayload = {
    membersState,
    setMembersState,
    selectedMembers,
  };

  return payload;
};
