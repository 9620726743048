"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.enableTeamForAtCapacity = exports.disableTeamForAtCapacity = exports.isDivisionAtCapacity = exports.AT_CAPACITY_DISABLED_REASON = void 0;
var capacity_1 = require("../../../tournaments/registration/Wizard/components/DivisionCard/utils/capacity");
exports.AT_CAPACITY_DISABLED_REASON = 'You have selected the maximum number of teams allowed for this division.';
var isDivisionAtCapacity = function (division, teamSelections) {
    var numberOfSpotsLeft = (0, capacity_1.getNumberOfSpotsLeft)(division);
    return (numberOfSpotsLeft !== undefined &&
        teamSelections.filter(function (_a) {
            var teamId = _a.teamId;
            return teamId.length;
        }).length >=
            numberOfSpotsLeft);
};
exports.isDivisionAtCapacity = isDivisionAtCapacity;
var disableTeamForAtCapacity = function (teamOption, selections) {
    return __assign(__assign({}, teamOption), { disabled: !teamOption.checkboxState ||
            !!selections.find(function (teamSelection) { return teamSelection.teamId === teamOption.value; }), disabledReason: teamOption.disabled
            ? teamOption.disabledReason
            : exports.AT_CAPACITY_DISABLED_REASON });
};
exports.disableTeamForAtCapacity = disableTeamForAtCapacity;
var enableTeamForAtCapacity = function (teamOption) {
    return __assign(__assign({}, teamOption), { disabled: teamOption.disabledReason === exports.AT_CAPACITY_DISABLED_REASON
            ? false
            : teamOption.disabled, disabledReason: teamOption.disabledReason === exports.AT_CAPACITY_DISABLED_REASON
            ? undefined
            : teamOption.disabledReason });
};
exports.enableTeamForAtCapacity = enableTeamForAtCapacity;
