import { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import {
  BillingSummaryRequestItem,
  CartItemData,
  PaymentOptionsData,
  PaymentOptionsItem,
} from '../Checkout.types';
import CheckoutCard, {
  CheckoutCardBody,
  CheckoutCardFooter,
  CheckoutCardPrimaryButton,
} from '../CheckoutCard/CheckoutCard';
import { DiscountBadge } from '../DiscountBadge/DiscountBadge';
import ApplyDiscountCodesSection from '../DiscountCodes/ApplyDiscountCodesSection';
import PaymentOptionsModal, {
  PAY_IN_FULL_DETAIL,
  PAY_IN_FULL_VALUE,
  PaymentOptionsFormFields,
} from '../PaymentOptionsModal/PaymentOptionsModal';
import { getPaymentPlanSubTotal } from '../utils/paymentPlan';
import * as S from './PaymentOptionsCard.styles';

export type PaymentOptionsCardProps = { cartItems: CartItemData[] };

/*PaymentOptionsCard */
export default function PaymentOptionsCard({
  cartItems,
}: PaymentOptionsCardProps) {
  const { mcShowDiscountCodes } = useFlags();

  const [isPaymentOptionsModalOpen, setIsPaymentOptionsModalOpen] =
    useState(false);

  const {
    selectedPaymentOptions,
    updateHasAutopayPaymentOptionSelected,
    updateSelectedPaymentOptions,
  } = useCheckoutInfo();

  const paymentTermsData = cartItems.map(
    (item) => buildPaymentOptionsData(item, selectedPaymentOptions),
    []
  );

  const { cartCheckoutPaymentTerms } = useFlags();

  const updateSelectedPaymentOptionsData = (
    paymentOptionsFormFields: PaymentOptionsFormFields
  ): void => {
    const paymentOptions = cartItems.flatMap(
      (cartItem) => cartItem.options.program.paymentPlanSummaries ?? []
    );

    let hasAutopayPaymentOptionSelected = false;

    const selectedPaymentOptions = Object.entries(
      paymentOptionsFormFields
    ).map<BillingSummaryRequestItem>(([cartItemUuid, option]) => {
      const paymentOption = paymentOptions.find(
        (paymentOption) =>
          paymentOption.ngPaymentPlanId === option.paymentOption
      );
      const autopayRequired = !!paymentOption?.autopayRequired;

      if (option.paymentOption !== 'FULL' && paymentOption && autopayRequired) {
        hasAutopayPaymentOptionSelected = true;
      }

      return {
        autopayRequired,
        cartItemUuid,
        payLater: option.payLater,
        paymentTerm: option.paymentOption === 'FULL' ? 'FULL' : 'PAYMENT_PLAN',
        paymentPlanId:
          option.paymentOption === 'FULL' ? undefined : option.paymentOption,
      };
    });

    updateSelectedPaymentOptions(selectedPaymentOptions);
    updateHasAutopayPaymentOptionSelected(hasAutopayPaymentOptionSelected);
    setIsPaymentOptionsModalOpen(false);
  };

  return (
    <>
      <CheckoutCard cardName="Payment options">
        <CheckoutCardBody>
          <PaymentOptionsBody paymentOptionsData={paymentTermsData} />
        </CheckoutCardBody>
        {cartCheckoutPaymentTerms && (
          <CheckoutCardFooter>
            <CheckoutCardPrimaryButton
              label="Edit payment options"
              clickHandler={() => setIsPaymentOptionsModalOpen(true)}
            />
          </CheckoutCardFooter>
        )}
        {mcShowDiscountCodes ? <ApplyDiscountCodesSection /> : null}
      </CheckoutCard>
      <PaymentOptionsModal
        onSubmit={updateSelectedPaymentOptionsData}
        open={isPaymentOptionsModalOpen}
        closeModal={() => setIsPaymentOptionsModalOpen(false)}
        paymentOptionsData={paymentTermsData}
      />
    </>
  );
}

function buildPaymentOptionsData(
  cartItem: CartItemData,
  selectedPaymentOptions?: BillingSummaryRequestItem[]
): PaymentOptionsItem {
  const tournamentName = cartItem.options.program.programName;
  const subTotal = cartItem.subtotal;

  const paymentOptions = cartItem.options.program.paymentPlanSummaries ?? [];
  const payInFull =
    cartItem.options.program.paymentRules?.payInFull ||
    paymentOptions.length === 0;
  const selectedPaymentOption = selectedPaymentOptions?.find(
    (item) => item.cartItemUuid === cartItem.cartItemUuid
  );

  let paymentOption, paymentDetail, priceAdjustment;
  if (
    selectedPaymentOption &&
    selectedPaymentOption.paymentTerm !== 'FULL' &&
    selectedPaymentOption.paymentPlanId
  ) {
    const { paymentPlanId } = selectedPaymentOption;
    const selectedPaymentOptionDetails = paymentOptions.find(
      (paymentPlan) => paymentPlan.ngPaymentPlanId === paymentPlanId
    );
    paymentOption = paymentPlanId;
    paymentDetail = selectedPaymentOptionDetails?.name ?? '';
    priceAdjustment = selectedPaymentOptionDetails?.priceAdjustment;
  } else if (!selectedPaymentOption && paymentOptions.length) {
    const firstPaymentPlan = paymentOptions[0];
    const paymentPlanSubTotal = getPaymentPlanSubTotal(firstPaymentPlan);

    if (!payInFull || paymentPlanSubTotal < Number(subTotal)) {
      paymentOption = firstPaymentPlan.ngPaymentPlanId;
      paymentDetail = firstPaymentPlan.name;
      priceAdjustment = firstPaymentPlan.priceAdjustment;
    }
  }

  if (!paymentOption || !paymentDetail) {
    paymentOption = PAY_IN_FULL_VALUE;
    paymentDetail = PAY_IN_FULL_DETAIL;
  }

  return {
    itemId: cartItem.cartItemUuid,
    payInFull,
    payLater: selectedPaymentOption?.payLater,
    paymentOptions,
    paymentDetail,
    paymentOption,
    priceAdjustment,
    subTotal,
    tournamentName,
  };
}

/* */

/*PaymentOptionsBody */
function PaymentOptionsBody({
  paymentOptionsData,
}: {
  paymentOptionsData: PaymentOptionsData;
}) {
  return (
    <S.PaymentOptionsBody>
      <PaymentOptionsItems paymentOptionsData={paymentOptionsData} />
    </S.PaymentOptionsBody>
  );
}
/* */

/*PaymentOptionsItems */
function PaymentOptionsItems({
  paymentOptionsData,
}: {
  paymentOptionsData: PaymentOptionsData;
}) {
  return (
    <>
      {paymentOptionsData.map(
        ({ priceAdjustment, tournamentName, paymentDetail }, idx) => (
          <S.PaymentOptionsItem key={`paymentOptionsItem-${idx}`}>
            <S.TournamentName>{tournamentName}</S.TournamentName>
            <S.PaymentDetail>
              {priceAdjustment ? (
                <DiscountBadge priceAdjustment={priceAdjustment} />
              ) : null}
              {paymentDetail}
            </S.PaymentDetail>
          </S.PaymentOptionsItem>
        )
      )}
    </>
  );
}
/* */
