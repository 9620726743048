import styled from 'styled-components/macro';
import { ThemedComponent } from '@la/ds-ui-components';
import { getCursorProperty } from '../utils/drag';

export const MemberOption = styled.li<{
  $hideOverflow?: boolean;
  $selectable?: boolean;
  $selected?: boolean;
  $disabled?: boolean;
}>(({ $hideOverflow, $selectable, $selected, $disabled }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '36px',
  margin: 0,
  padding: 0,
  borderRadius: '4px',
  overflow: $hideOverflow ? 'hidden' : 'visible',
  cursor: getCursorProperty($selected, $selectable, $disabled),
}));

export const DragOverlay = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    background: theme.foundational.blueGrey[50],
    width: '100%',
    height: '36px',
    position: 'absolute',
    top: '0',
    left: '0',
    border: '8px solid transparent',
    borderRadius: '4px',
    pointerEvents: 'none',
  })
);
