import { useEffect, useState } from 'react';
import { FormField, FormFieldResponse, UserType } from '@la/types';
import { Team } from 'redux/services/types/team';
import { WaiverData } from 'domains/Checkout/Checkout.types';
import { getAccountInfo } from './getAccountInfo';
import { getFormFields, getMemberFormFields } from './getFormFields';
import { GroupAccount, getGroupAccount } from './getGroupAccount';
import { getProgram } from './getProgram';
import { getStructuredStateSetting } from './getStructuredStateSetting';
import { getTeam } from './getTeam';
import { getWaivers } from './getWaivers';
import {
  DecodedTokenData,
  ErrorResponse,
  verifyUrlSignature,
} from './verifyUrlSignature';

type Program = {
  id?: number;
  masterProgramId: number;
  name: string;
  details: {
    hotelLinks?: string;
  };
};

export type RegistrantInfoPayload = {
  decodedData: DecodedTokenData;
  formFields: FormFieldResponse;
  groupAccount: GroupAccount | null;
  loggedInUserId: number;
  masterProgram: Program;
  memberFormFields: FormField[];
  program: Program;
  structuredStateEnabled: boolean;
  team: Team;
  waivers: WaiverData[];
};

/*
 * This is a temporary handler until we figure it out the BFF authentication problem.
 * Ideally all this info will come from the BFF so once we solve the problem we will a hook
 * using rtk-query responsible for fetching everything all at once from the BFF and delete this handler.
 */
const getRegistrationInfoFromFacade = async (encodedToken: string) => {
  try {
    const decodedData = await verifyUrlSignature(encodedToken);

    const accountInfo = await getAccountInfo({ siteId: decodedData.site });

    const team = await getTeam({
      orgId: accountInfo.orgAccount.id,
      teamId: decodedData.team,
    });

    const program = await getProgram({
      siteId: decodedData.site,
      programId: decodedData.prid,
    });

    const masterProgram = await getProgram({
      siteId: decodedData.site,
      programId: program.masterProgramId,
    });

    const playerRoles =
      decodedData.role === 'player' ? ['teamPlayer'] : undefined;

    const staffRoles =
      decodedData.role !== 'player' ? [parseInt(decodedData.role)] : undefined;

    const formFields = await getFormFields({
      siteId: decodedData.site,
      programId: decodedData.prid,
      playerRoles,
      staffRoles,
    });

    let userType: UserType = 'any';
    if (decodedData.type === 'player') {
      userType = 'child';
    } else {
      userType = 'adult';
    }
    const memberFormFields = await getMemberFormFields({
      siteId: decodedData.site,
      userType,
      playerRoles,
      staffRoles,
    });

    const waivers = await getWaivers({
      siteId: decodedData.site,
      programId: decodedData.prid,
      playerRoles,
      staffRoles,
    });

    const groupAccount = await getGroupAccount({ siteId: decodedData.site });

    const structuredStateEnabled: boolean = await getStructuredStateSetting(
      decodedData.site
    );

    return {
      decodedData,
      team,
      program,
      formFields,
      loggedInUserId: accountInfo.user.id,
      memberFormFields,
      waivers,
      groupAccount,
      masterProgram,
      structuredStateEnabled,
    };
  } catch (e) {
    console.error(e);
  }
};

const useGetRegistrationInfoFromFacade = (encodedToken: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [data, setData] = useState<RegistrantInfoPayload | null>(null);

  useEffect(() => {
    window.history.pushState({}, document.title, window.location.pathname);
    setLoading(true);
    getRegistrationInfoFromFacade(encodedToken)
      .then((data) => {
        setLoading(false);
        if (data) {
          setData(data);
        }
      })
      .catch((err) => {
        setError(err as ErrorResponse);
        setLoading(false);
      });
  }, [encodedToken]);

  return { loading, error, data };
};

export { useGetRegistrationInfoFromFacade };
