"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWizardState = void 0;
var react_1 = require("react");
var toolkit_1 = require("@reduxjs/toolkit");
var launchdarkly_react_client_sdk_1 = require("launchdarkly-react-client-sdk");
var WizardState_1 = require("../WizardState");
function useWizardState(_a) {
    var initialState = _a.initialState;
    var _b = (0, toolkit_1.createSlice)({
        name: 'registrationWizard',
        initialState: initialState,
        reducers: WizardState_1.registrationWizardReducers,
    }), _c = _b.actions, selectDivision = _c.selectDivision, cancelDivisionChanges = _c.cancelDivisionChanges, addTeamSelect = _c.addTeamSelect, discardTeamSelect = _c.discardTeamSelect, selectTeam = _c.selectTeam, saveDivision = _c.saveDivision, editDivision = _c.editDivision, clearDivision = _c.clearDivision, submit = _c.submit, clearIdToScrollTo = _c.clearIdToScrollTo, reducer = _b.reducer;
    var _d = (0, react_1.useReducer)(reducer, initialState), state = _d[0], dispatch = _d[1];
    var _e = (0, launchdarkly_react_client_sdk_1.useFlags)().mcTeamSelectionVisualUpdates, mcTeamSelectionVisualUpdates = _e === void 0 ? true : _e;
    return __assign(__assign({}, state), { selectDivision: function (divisionId) {
            return dispatch(selectDivision({ divisionId: divisionId }));
        }, cancelDivisionChanges: function (divisionId) {
            return dispatch(cancelDivisionChanges({ divisionId: divisionId }));
        }, addTeamSelect: function (divisionId) {
            return dispatch(addTeamSelect({ divisionId: divisionId }));
        }, discardTeamSelect: function (divisionId, selectIndex) {
            return dispatch(discardTeamSelect({ divisionId: divisionId, selectIndex: selectIndex }));
        }, selectTeam: function (divisionId, selectIndex, teamId) {
            return dispatch(selectTeam({
                divisionId: divisionId,
                selectIndex: selectIndex,
                teamId: teamId,
            }));
        }, saveDivision: function (divisionId) {
            return dispatch(saveDivision({ divisionId: divisionId, mcTeamSelectionVisualUpdates: mcTeamSelectionVisualUpdates }));
        }, editDivision: function (divisionId) {
            return dispatch(editDivision({ divisionId: divisionId }));
        }, clearDivision: function (divisionId) {
            return dispatch(clearDivision({ divisionId: divisionId }));
        }, submit: function () { return dispatch(submit()); }, clearIdToScrollTo: function () { return dispatch(clearIdToScrollTo()); } });
}
exports.useWizardState = useWizardState;
