"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTeamSelectId = exports.getTeamOptionLabel = exports.DivisionCardTeamSelectionSection = exports.DIVISION_NOT_SAVED_ALERT_MESSAGE = exports.EMPTY_SELECT_ERROR_MESSAGE = exports.TEAM_SELECT_PLACEHOLDER_OPTION_TEXT = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var ds_ui_components_1 = require("@la/ds-ui-components");
var services_1 = require("@la/services");
var hooks_1 = require("../../../../../../lib/hooks");
var breakpoints_1 = require("../../../../../../lib/media-queries/breakpoints");
var TeamRegistrationContext_1 = require("../../../../TeamRegistration/TeamRegistrationContext");
var DivisionCard_1 = require("../DivisionCard");
var capacity_1 = require("../utils/capacity");
var UpdateTeamDialog_1 = require("./UpdateTeamDialog");
var DivisionCard_styles_1 = require("../DivisionCard.styles");
var S = require("./DivisionCardTeamSelectionSection.styles");
exports.TEAM_SELECT_PLACEHOLDER_OPTION_TEXT = 'Select or create a team';
exports.EMPTY_SELECT_ERROR_MESSAGE = 'Team not selected. Select a team or discard it.';
exports.DIVISION_NOT_SAVED_ALERT_MESSAGE = 'Division not saved. One or more team selections were left blank.';
function DivisionCardTeamSelects(_a) {
    var _b;
    var cardState = _a.cardState, division = _a.division, maxDivisionAgeGroup = _a.maxDivisionAgeGroup, onClear = _a.onClear, onDeleteTeam = _a.onDeleteTeam, onDiscardTeamSelect = _a.onDiscardTeamSelect, onSelectTeam = _a.onSelectTeam, openCreateTeamDialog = _a.openCreateTeamDialog, openDiscardConfirmationDialog = _a.openDiscardConfirmationDialog, teamsList = _a.teamsList, tournamentId = _a.tournamentId, setHasDeleteTeamDivisionError = _a.setHasDeleteTeamDivisionError, onUpdateTeam = _a.onUpdateTeam;
    var divisionId = division.id;
    var siteId = (0, react_router_dom_1.useParams)().siteId;
    var payer = (0, react_1.useContext)(TeamRegistrationContext_1.TeamRegistrationContext).state.payer;
    var data = (0, services_1.useGetUserRegistrationsQuery)({
        siteDomain: 'svssc',
        programId: division.id,
        payerId: payer ? payer.id.toString() : undefined,
        siteId: siteId,
    }).data;
    var createNewTeamOption = {
        label: '+ Create new team',
        value: 'create-new-team',
    };
    var teamSelections = cardState.teamSelections;
    var registeredTeams = (_b = division.registrationsForUser) === null || _b === void 0 ? void 0 : _b.team.filter(function (registration) { return registration.registrationStatus === 'REGISTERED'; }).map(function (registration) { return registration.teamId; });
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: teamSelections.map(function (teamSelection, selectIndex) {
            var _a, _b;
            var teamId = teamSelection.teamId, hasError = teamSelection.hasError;
            var team = teamsList.find(function (team) { return team.id === teamId; });
            var selectOptions = __spreadArray(__spreadArray([], teamsList.map(function (team) {
                return {
                    label: getTeamOptionLabel(team, registeredTeams),
                    value: team.id,
                    disabled: (teamSelections.find(function (teamSelectionInner) { return teamSelectionInner.teamId === team.id; }) &&
                        team.id !== teamId) ||
                        (registeredTeams && registeredTeams.includes(team.id)),
                };
            }), true), [
                createNewTeamOption,
            ], false);
            var onSelectChange = function (newValue) {
                if (newValue === createNewTeamOption.value) {
                    openCreateTeamDialog(selectIndex);
                }
                else {
                    onSelectTeam(selectIndex, newValue);
                }
            };
            var onDiscard = function () {
                if (teamSelections.length === 1) {
                    if (cardState.value === 'saved-and-editing') {
                        openDiscardConfirmationDialog();
                    }
                    else {
                        onClear();
                    }
                }
                else {
                    onDiscardTeamSelect(selectIndex);
                }
            };
            var selectId = getTeamSelectId(divisionId, selectIndex);
            var errorMessageId = "".concat(selectId, "-error-message");
            var label = "Team ".concat(selectIndex + 1);
            var teamName = (_a = selectOptions.find(function (option) { return option.value === teamId; })) === null || _a === void 0 ? void 0 : _a.label;
            var hasDivisionsWithTeamSelected = (_b = data === null || data === void 0 ? void 0 : data.userRegistrations.some(function (registration) { return registration.teamId === teamId; })) !== null && _b !== void 0 ? _b : false;
            return ((0, jsx_runtime_1.jsxs)(S.DivisionCardTeamSelection, { children: [(0, jsx_runtime_1.jsxs)(S.DivisionCardTeamSelectionTopRow, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Select, { id: selectId, label: label, options: selectOptions, placeholder: exports.TEAM_SELECT_PLACEHOLDER_OPTION_TEXT, value: teamId, onChange: onSelectChange, hasError: hasError, "aria-describedby": errorMessageId }), team ? ((0, jsx_runtime_1.jsx)(UpdateTeamDialog_1.UpdateTeamDialog, { divisionId: divisionId, maxDivisionAgeGroup: maxDivisionAgeGroup, hasDivisionsWithTeamSelected: hasDivisionsWithTeamSelected, onDeleteTeam: onDeleteTeam, onUpdateTeam: onUpdateTeam, team: team, tournamentId: tournamentId, setHasDeleteTeamDivisionError: setHasDeleteTeamDivisionError, customFields: division.customTeamFields })) : null, (0, jsx_runtime_1.jsx)("span", __assign({ style: {
                                    cursor: 'pointer',
                                    marginBottom: '12px',
                                    marginLeft: '12px',
                                }, onClick: onDiscard, "aria-label": teamName
                                    ? "Discard team ".concat(teamName)
                                    : "Discard team ".concat(selectIndex + 1) }, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.TrashBinIcon, { fill: "var(--green-600)", variant: "regular", size: "medium" }) }))] }), hasError ? ((0, jsx_runtime_1.jsx)(S.DivisionCardTeamSelectionErrorMessage, __assign({ id: errorMessageId, "data-testid": errorMessageId }, { children: exports.EMPTY_SELECT_ERROR_MESSAGE }))) : null] }, selectIndex));
        }) }));
}
function DivisionCardTeamSelectionSection(_a) {
    var cardId = _a.cardId, cardState = _a.cardState, division = _a.division, divisionCardsState = _a.divisionCardsState, maxDivisionAgeGroup = _a.maxDivisionAgeGroup, onAddTeamSelect = _a.onAddTeamSelect, onClear = _a.onClear, onDeleteTeam = _a.onDeleteTeam, onDiscardTeamSelect = _a.onDiscardTeamSelect, onSelectTeam = _a.onSelectTeam, openCreateTeamDialog = _a.openCreateTeamDialog, openDiscardConfirmationDialog = _a.openDiscardConfirmationDialog, teamsList = _a.teamsList, tournamentId = _a.tournamentId, variant = _a.variant, onUpdateTeam = _a.onUpdateTeam, setHasDeleteTeamDivisionError = _a.setHasDeleteTeamDivisionError;
    var addTeamSelectButtonId = "".concat(cardId, "-add-team-select");
    var numberOfSpotsLeft = (0, capacity_1.getNumberOfSpotsLeft)(division);
    var isTabletPortraitUp = (0, hooks_1.useMediaQuery)(breakpoints_1.breakpointQueries.tabletLandscapeUp);
    return ((0, jsx_runtime_1.jsx)(DivisionCard_styles_1.DivisionCardSection, __assign({ "$variant": variant }, { children: (0, jsx_runtime_1.jsxs)(S.DivisionCardTeams, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ size: isTabletPortraitUp ? 'large' : 'medium', variant: "ui", weight: "bold" }, { children: "Add your teams" })), cardState.isShowingDivisionNotSavedMessage ? ((0, jsx_runtime_1.jsx)(S.DivisionCardTeamSelectionAlertMessage, __assign({ role: "alert" }, { children: exports.DIVISION_NOT_SAVED_ALERT_MESSAGE }))) : null, (0, jsx_runtime_1.jsx)(DivisionCardTeamSelects, { onUpdateTeam: onUpdateTeam, cardState: cardState, division: division, divisionCardsState: divisionCardsState, maxDivisionAgeGroup: maxDivisionAgeGroup, onClear: onClear, onDeleteTeam: onDeleteTeam, onDiscardTeamSelect: onDiscardTeamSelect, onSelectTeam: onSelectTeam, openCreateTeamDialog: openCreateTeamDialog, openDiscardConfirmationDialog: openDiscardConfirmationDialog, teamsList: teamsList, tournamentId: tournamentId, setHasDeleteTeamDivisionError: setHasDeleteTeamDivisionError }), !!numberOfSpotsLeft &&
                    cardState.teamSelections.length >= numberOfSpotsLeft ? ((0, jsx_runtime_1.jsx)(S.DivisionSpotsLeftMessage, __assign({ size: "medium", variant: "ui", weight: "bold" }, { children: numberOfSpotsLeft <= 0
                        ? 'Division has no spots left'
                        : "Division only has ".concat(numberOfSpotsLeft === 1 ? '1 spot' : "".concat(numberOfSpotsLeft, " spots"), " left") }))) : ((0, jsx_runtime_1.jsx)(S.DivisionCardAddTeamSelectButton, __assign({ id: addTeamSelectButtonId, "data-testid": addTeamSelectButtonId, variant: "text", leftIcon: (0, jsx_runtime_1.jsx)(ds_ui_components_1.PlusCircleIcon, { fill: "var(--green-600)", size: "large" }), onClick: onAddTeamSelect, size: "medium" }, { children: "Add another team" })))] }) })));
}
exports.DivisionCardTeamSelectionSection = DivisionCardTeamSelectionSection;
/**
 * Returns the option label for a team that includes the name and age group
 * in order to help the user distinguish which team they are selecting.
 *
 * @param team The team to get the option label parts from
 * @returns A string in the format of <team name> (<age group>)
 */
function getTeamOptionLabel(team, registeredTeams) {
    var ageGroup = team.ageGroup === 0 ? 'All ages' : (0, DivisionCard_1.ageGroupDisplay)(team.ageGroup);
    var draftText = team.status === 'DRAFT' ? ' (draft)' : '';
    var registeredText = registeredTeams && registeredTeams.includes(team.id) ? '(Registered)' : '';
    return "".concat(team.name, " (").concat(ageGroup, ")").concat(draftText).concat(registeredText);
}
exports.getTeamOptionLabel = getTeamOptionLabel;
/**
 * Each team select needs a unique ID. This creates one using the division ID and
 * the 1-indexed "team number" as it is displayed as the select label in the UI.
 *
 * @param division The tournament division
 * @param selectIndex The array index of the select
 * @returns A string to use as the ID of a team select
 */
function getTeamSelectId(divisionId, selectIndex) {
    return "division-".concat(divisionId, "-team-select-").concat(selectIndex + 1);
}
exports.getTeamSelectId = getTeamSelectId;
