"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditTeamFieldsModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_hook_form_1 = require("react-hook-form");
var yup_1 = require("@hookform/resolvers/yup");
var yup = require("yup");
var ds_ui_components_1 = require("@la/ds-ui-components");
var utilities_1 = require("@la/utilities");
var hooks_1 = require("../../lib/hooks");
var breakpoints_1 = require("../../lib/media-queries/breakpoints");
var CreateTeamForm_1 = require("../../tournaments/registration/Wizard/components/CreateTeamForm/CreateTeamForm");
var CustomFieldsForm_1 = require("../../tournaments/registration/Wizard/components/CustomFieldsForm/CustomFieldsForm");
var validation_1 = require("../TeamSelectionSection/utils/validation");
var S = require("./EditTeamFieldsModal.styles");
var EDIT_TEAMS_FORM_ID = 'bulk-edit-teams';
var EditTeamFieldsModal = function (_a) {
    var teams = _a.teams, open = _a.open, onClose = _a.onClose, customFields = _a.customFields, addedTeamsIds = _a.addedTeamsIds, handleFieldsUpdate = _a.handleFieldsUpdate;
    var _b = (0, react_1.useState)(teams[0].id), selectedTeam = _b[0], setSelectedTeam = _b[1];
    var isTabletPortaitUp = (0, hooks_1.useMediaQuery)(breakpoints_1.breakpointQueries.tabletPortraitUp);
    var pendingTeams = (0, validation_1.getIncompleteRequiredFieldsTeams)(customFields, teams.filter(function (team) { return addedTeamsIds.includes(team.id); }));
    var teamFieldsValidation = pendingTeams.reduce(function (schema, team) { return schema.concat((0, CustomFieldsForm_1.createValidationSchema)(team.id)); }, yup.object({}));
    var editTeamsFieldsValidation = yup.object({}).concat(teamFieldsValidation);
    var defaultValues = pendingTeams.reduce(function (p, c) {
        var _a;
        var _b;
        return __assign(__assign({}, p), (_a = {}, _a[c.id] = (0, CreateTeamForm_1.extractCustomFieldValue)(customFields, (_b = teams.find(function (t) { return t.id === c.id; })) === null || _b === void 0 ? void 0 : _b.formFields), _a));
    }, {});
    var methods = (0, react_hook_form_1.useForm)({
        defaultValues: defaultValues,
        resolver: (0, yup_1.yupResolver)(editTeamsFieldsValidation),
    });
    (0, react_1.useEffect)(function () {
        var element = document.getElementById(selectedTeam);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [selectedTeam]);
    var renderTeams = function () {
        return pendingTeams.map(function (team) {
            var isSelected = selectedTeam === team.id;
            return ((0, jsx_runtime_1.jsx)(S.TeamItemContainer, __assign({ "$isSelected": isSelected, onClick: function () { return setSelectedTeam(team.id); } }, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large", weight: isSelected ? 'bold' : 'regular' }, { children: (0, utilities_1.truncateWithEllipsis)(team.name, 25) })) }), team.id));
        });
    };
    var renderFields = function () {
        return pendingTeams.map(function (team) {
            return ((0, jsx_runtime_1.jsxs)(S.TeamContainer, __assign({ id: team.id }, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large", weight: "bold" }, { children: team.name })), (0, jsx_runtime_1.jsx)(S.TeamFieldsContainer, __assign({ "$isTabletPortraitUp": isTabletPortaitUp }, { children: (0, jsx_runtime_1.jsx)(S.CustomField, { children: (0, jsx_runtime_1.jsx)(CustomFieldsForm_1.CustomFields, { inputSize: "large", columns: isTabletPortaitUp ? 2 : 1, name: team.id }) }) }), team.id)] }), team.id));
        });
    };
    return ((0, jsx_runtime_1.jsx)(ds_ui_components_1.Modal, __assign({ size: "large", open: open, onOpenChange: onClose, title: "Add team details", primaryAction: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Button, __assign({ form: EDIT_TEAMS_FORM_ID, type: "submit" }, { children: "Save and continue" })), tertiaryAction: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Button, __assign({ variant: "text", onClick: onClose }, { children: "Discard changes" })) }, { children: (0, jsx_runtime_1.jsxs)(S.EditTeamsContainer, { children: [isTabletPortaitUp ? ((0, jsx_runtime_1.jsx)(S.TeamsLeftPanel, { children: (0, jsx_runtime_1.jsx)(S.TeamLeftPanelInnerContainer, { children: renderTeams() }) })) : null, (0, jsx_runtime_1.jsxs)(S.EditTeamsContent, __assign({ "$isTabletPortraitUp": isTabletPortaitUp }, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large" }, { children: "Before you continue, this program requires additional information. Please complete the following fields before completing registration." })), (0, jsx_runtime_1.jsx)(S.FormFieldsContainer, { children: (0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, __assign({}, methods, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Form, __assign({ id: EDIT_TEAMS_FORM_ID, noValidate: true, onSubmit: methods.handleSubmit(handleFieldsUpdate) }, { children: renderFields() })) })) })] }))] }) })));
};
exports.EditTeamFieldsModal = EditTeamFieldsModal;
