import { useState } from 'react';
import { ArrowCircleRight } from '@phosphor-icons/react';
import { breakpointQueries, useMediaQuery } from '@la/shared-components';
import { StaffRole } from '@la/types';
import { TeamRoster } from 'redux/services/rosterManagementApi';
import { AssignStaffRolesModal } from './AssignStaffRolesModal/AssignStaffRolesModal';
import { DestinationRolloverDropzone } from './RolloverDropzone/DestinationRolloverDropzone';
import { OriginRolloverDropzone } from './RolloverDropzone/OriginRolloverDropzone';
import { RolloverTeamState } from './RolloverDropzone/RolloverTeam/RolloverTeam';
import {
  MemberType,
  PlayerMemberState,
  StaffMemberState,
} from './RolloverDropzone/RolloverTeam/types/member';
import * as S from './RolloverWizard.styles';

export type RolloverWizardProps = {
  /**
   * Team roster the user is rolling members to.
   */
  destinationTeamRoster: TeamRoster;
  /**
   * When the user clicks a trigger for the team selection modal.
   */
  onTeamSelectionClick: () => void;
  /**
   * Team roster the user is rolling members from.
   */
  selectedTeamRoster?: TeamRoster;
  /**
   * Available staff roles to the new rolling staff members.
   */
  staffRoles: StaffRole[];
};

function getInitialMemberState(teamRoster: TeamRoster): RolloverTeamState {
  const playersState: PlayerMemberState[] = teamRoster.players.map(
    (player) => ({
      ...player,
      // TODO: Replace hardcoded value for `ageGroup`
      ageGroup: 8,
      selected: false,
      type: MemberType.Player,
    })
  );
  const staffState: StaffMemberState[] = teamRoster.staff.map((staff) => ({
    ...staff,
    // TODO: Replace hardcoded value for `roleId` and `roleName`
    roleId: 1,
    roleName: staff.role ?? '',
    selected: false,
    type: MemberType.Staff,
  }));

  return { players: playersState, staff: staffState };
}

const getIncludedTeamRoster = (
  originRoster: TeamRoster,
  destinationRoster: TeamRoster
): TeamRoster => {
  const destinationStaffIds = destinationRoster.staff.map(
    (staff) => staff.userId
  );
  const destinationPlayerIds = destinationRoster.players.map(
    (player) => player.userId
  );

  const includedRoster: TeamRoster = {
    team: originRoster.team,
    staff: originRoster.staff.filter((staff) =>
      destinationStaffIds.includes(staff.userId)
    ),
    players: originRoster.players.filter((player) =>
      destinationPlayerIds.includes(player.userId)
    ),
  };

  return includedRoster;
};

export function RolloverWizard({
  destinationTeamRoster,
  onTeamSelectionClick,
  selectedTeamRoster,
  staffRoles,
}: Readonly<RolloverWizardProps>) {
  const isTabletPortaitUp = useMediaQuery(breakpointQueries.tabletPortraitUp);

  const initialRoster = {
    team: destinationTeamRoster.team,
    players: [],
    staff: [],
  };

  const [newRoster, setNewRoster] = useState<TeamRoster>(initialRoster);

  const [destinationDropzoneIsHovered, setDestinationDropzoneIsHovered] =
    useState<boolean>(false);

  const [isAssignStaffRolesModalOpen, setIsAssignStaffRolesModalOpen] =
    useState(false);

  const [rolloverMembersState, setRolloverMembersState] =
    useState<RolloverTeamState>(getInitialMemberState(initialRoster));

  const onOriginDragEnd = (): void => {
    setDestinationDropzoneIsHovered(false);
  };

  const onOriginDragStart = (): void => {
    setDestinationDropzoneIsHovered(true);
  };

  const onRollover = (teamRoster: Omit<TeamRoster, 'team'>) => {
    setNewRoster(({ players, staff, team }) => {
      return {
        team,
        players: [...players, ...teamRoster.players],
        staff: [...staff, ...teamRoster.staff],
      };
    });

    if (teamRoster.staff.length) {
      setIsAssignStaffRolesModalOpen(true);
    }
  };

  const onRolloverClick = () => {
    if (rolloverMembersState) {
      const includedPlayers = includedRoster.players.map((p) => p.userId);
      const includedStaff = includedRoster.staff.map((s) => s.userId);
      onRollover({
        staff: rolloverMembersState.staff.filter(
          (member) => !includedStaff.includes(member.userId)
        ),
        players: rolloverMembersState.players.filter(
          (member) => !includedPlayers.includes(member.userId)
        ),
      });
    }
  };

  const includedRoster = getIncludedTeamRoster(
    selectedTeamRoster ?? initialRoster,
    newRoster
  );

  return (
    <S.RolloverWizard>
      <OriginRolloverDropzone
        onDragEnd={onOriginDragEnd}
        onDragStart={onOriginDragStart}
        onTeamSelectionClick={onTeamSelectionClick}
        teamRoster={selectedTeamRoster}
        includedRoster={includedRoster}
        onSelectedStateChange={(state) => setRolloverMembersState(state)}
      />
      <S.RolloverButton
        leftIcon={
          <ArrowCircleRight fill="var(--secondary-600)" weight="bold" />
        }
        size="xl"
        variant="outline"
        width={isTabletPortaitUp ? 'auto' : '100%'}
        onClick={onRolloverClick}
      >
        Rollover
      </S.RolloverButton>
      <DestinationRolloverDropzone
        showHoveredState={destinationDropzoneIsHovered}
        teamRoster={newRoster}
        onRollover={onRollover}
      />
      {isAssignStaffRolesModalOpen ? (
        <AssignStaffRolesModal
          open={isAssignStaffRolesModalOpen}
          onOpenChange={() => setIsAssignStaffRolesModalOpen(false)}
          staffRoster={newRoster.staff}
          staffRoles={staffRoles ?? []}
          setNewRoster={setNewRoster}
        />
      ) : null}
    </S.RolloverWizard>
  );
}
