import styled from 'styled-components';
import { ThemedComponent } from '@la/ds-ui-components';

export const CapacityProgressBarContainer = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => {
    return {
      width: 320,

      [theme.mediaQueries.mobile]: {
        width: 240,
      },
    };
  }
);

export const CapacityProgressBar = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.foundational.blueGrey[50],
    position: 'relative',
  })
);

export const CapacityProgressBarFooter = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 4,

    p: {
      color: theme.foundational.blueGrey[600],
    },

    svg: {
      fill: theme.foundational.green[600],
    },
  })
);

export const FilledSegment = styled.span<
  ThemedComponent<{ $percentage: number }>
>(({ theme, $percentage }: ThemedComponent<{ $percentage: number }>) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: `${$percentage}%`,
  backgroundColor: theme.foundational.green[600],
  borderRadius: '4px 0 0 4px',
}));

export const PendingSegment = styled.span<
  ThemedComponent<{ $percentage: number; $offset: number }>
>(
  ({
    theme,
    $percentage,
    $offset,
  }: ThemedComponent<{ $percentage: number; $offset: number }>) => ({
    position: 'absolute',
    top: 0,
    left: `${$offset}%`,
    height: '100%',
    width: `${$percentage}%`,
    backgroundColor: theme.foundational.blueGrey[400],
  })
);

export const CapacitySubtitleContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
});
