/* Apply Discount Codes Section */
import { ChangeEvent, useContext, useState } from 'react';
import { Tag as TagIcon } from '@phosphor-icons/react';
import { Button, TextInput } from '@la/ds-ui-components';
import { getUniqueStrings } from '@la/utilities';
import Tag from 'components/Tag/Tag';
import { ToastVariant } from 'components/Toast/Toast';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import { MessagingContext } from 'lib/context/MessagingContext';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { useGetBillingSummaryMutation } from 'redux/services/checkoutApi';
import { BillingSummaryData } from '../Checkout.types';
import * as S from '../PaymentOptionsCard/PaymentOptionsCard.styles';

export default function ApplyDiscountCodesSection() {
  const { subdomain } = getLAHostnameParts();
  const {
    discountCodeNames,
    selectedPaymentMethodType,
    selectedPaymentOptions,
    updateDiscountCodes,
  } = useCheckoutInfo();
  const { addMessage } = useContext(MessagingContext);
  const [discountCode, setDiscountCode] = useState('');
  const [discountCodeError, setDiscountCodeError] = useState('');
  const [getBillingSummary, { isLoading }] = useGetBillingSummaryMutation();

  function onUpdateDiscountCode(
    discountCodeName: string,
    updateType: 'addition' | 'removal'
  ) {
    if (
      updateType === 'addition' &&
      discountCodeNames.includes(discountCodeName)
    ) {
      onDuplicateCodeSubmission(discountCodeName);
      return;
    }

    const paymentMethod = selectedPaymentOptions
      ? selectedPaymentMethodType
      : 'CARD';
    const paymentTerms = selectedPaymentOptions;
    const discountCodes = updateDiscountCodesArray(
      discountCodeName,
      discountCodeNames,
      updateType
    );

    getBillingSummary({
      discountCodes: discountCodes,
      paymentMethod: paymentMethod,
      paymentTerms: paymentTerms,
      subdomain: subdomain,
    })
      .unwrap()
      .then((response) => {
        if (response.messages?.some((message) => message.level === 'WARN')) {
          throw Error('This code is invalid or expired.', {
            cause: 'Warning from code validation response.',
          });
        }
        onUpdateDiscountCodeSuccess(response, discountCodeName, updateType);
      })
      .catch((error) => onUpdateDiscountCodeError(error));
  }

  function onUpdateDiscountCodeSuccess(
    response: BillingSummaryData,
    discountCodeName: string,
    updateType: 'addition' | 'removal'
  ) {
    updateDiscountCodes(response.discountCodes ?? []);
    showSnackBar(discountCodeName, updateType);
    if (updateType === 'addition') {
      setDiscountCode('');
    }
  }

  function onDuplicateCodeSubmission(discountCodeName: string) {
    setDiscountCode('');
    showSnackBar(discountCodeName, 'duplicate');
  }

  function onUpdateDiscountCodeError(error: Error) {
    if (error.cause === 'Warning from code validation response.') {
      setDiscountCodeError(error.message);
    } else {
      console.log('error' + JSON.stringify(error));
    }
  }

  function updateDiscountCodesArray(
    discountCodeName: string,
    discountCodeNames: string[],
    updateType: 'addition' | 'removal'
  ) {
    return updateType === 'addition'
      ? [...discountCodeNames, discountCodeName]
      : discountCodeNames.filter((value) => {
          return value !== discountCodeName;
        });
  }

  function showSnackBar(
    discountCodeName: string,
    updateType: 'addition' | 'duplicate' | 'removal'
  ) {
    let message: string;
    let variant: ToastVariant;

    switch (updateType) {
      case 'addition':
        message = 'has been successfully applied.';
        variant = 'success';
        break;
      case 'duplicate':
        message = 'has already been applied.';
        variant = 'info';
        break;
      case 'removal':
        message = 'has been successfully removed.';
        variant = 'success';
        break;
      default:
        message = 'was not applied';
        variant = 'info';
        break;
    }

    addMessage('toast', {
      description: `"${discountCodeName}" ${message}`,
      variant: variant,
    });
  }

  function onDiscountCodeChange(evt: ChangeEvent<HTMLInputElement>) {
    setDiscountCodeError('');
    setDiscountCode(evt.target.value);
  }

  return (
    <S.ApplyDiscountCodesSection>
      <S.ApplyDiscountCodeDivider />
      <S.ApplyDiscountCodeSectionTitle>
        Apply discount code
      </S.ApplyDiscountCodeSectionTitle>
      <S.TextInputWithButton>
        <TextInput
          hasError={!!discountCodeError}
          errorMessage={discountCodeError}
          type="text"
          disabled={isLoading}
          id="apply-discount-codes"
          size="medium"
          value={discountCode}
          placeholder="Enter discount code"
          onChange={onDiscountCodeChange}
        />
        <Button
          onClick={() => onUpdateDiscountCode(discountCode, 'addition')}
          variant="outline"
          size="medium"
          disabled={
            discountCode.length === 0 || isLoading || !!discountCodeError
          }
        >
          Apply
        </Button>
      </S.TextInputWithButton>
      <DiscountCodeTags removeTagHandler={onUpdateDiscountCode} />
    </S.ApplyDiscountCodesSection>
  );
}

export function DiscountCodeTags({
  removeTagHandler,
}: {
  removeTagHandler: (
    discountCodeName: string,
    updateType: 'addition' | 'removal'
  ) => void;
}) {
  const { discountCodeNames } = useCheckoutInfo();
  const uniqueDiscountCodeNames = getUniqueStrings(discountCodeNames);
  const discountCodeTags = uniqueDiscountCodeNames.map(
    (discountCodeName, idx) => {
      return (
        <Tag
          closeHandler={() => removeTagHandler(discountCodeName, 'removal')}
          icon={<TagIcon width="16px" height="16px" weight="bold" />}
          label={discountCodeName}
          key={`${discountCodeName}-${idx}`}
        />
      );
    }
  );

  return (
    <S.DiscountCodesContainer>{discountCodeTags}</S.DiscountCodesContainer>
  );
}
