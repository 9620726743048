"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomField = exports.TeamFieldsContainer = exports.TeamContainer = exports.FormFieldsContainer = exports.TeamItemContainer = exports.EditTeamsContent = exports.TeamLeftPanelInnerContainer = exports.TeamsLeftPanel = exports.EditTeamsContainer = void 0;
var styled_components_1 = require("styled-components");
exports.EditTeamsContainer = styled_components_1.default.div({
    display: 'flex',
});
exports.TeamsLeftPanel = styled_components_1.default.div({
    position: 'fixed',
    top: 48,
    left: 0,
    width: 220,
    height: 'calc(100% - 106px)',
});
exports.TeamLeftPanelInnerContainer = styled_components_1.default.div({
    marginLeft: 24,
    marginTop: 16,
    maxHeight: 'calc(100% - 16px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
        width: 0,
        backgroundColor: 'transparent',
    },
});
exports.EditTeamsContent = styled_components_1.default.div(function (_a) {
    var $isTabletPortraitUp = _a.$isTabletPortraitUp;
    return {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: $isTabletPortraitUp ? 220 : 0,
        overflowX: 'hidden',
    };
});
exports.TeamItemContainer = styled_components_1.default.div(function (_a) {
    var theme = _a.theme, $isSelected = _a.$isSelected;
    var _b = theme.foundational, blueGrey = _b.blueGrey, green = _b.green, white = _b.white, semantic = theme.semantic;
    var borderColor = semantic ? semantic.primary[600] : green[600];
    var backgroundColor = semantic ? semantic.primary[35] : green[35];
    return {
        padding: 8,
        cursor: 'pointer',
        borderRight: $isSelected ? "4px solid ".concat(borderColor) : 'none',
        backgroundColor: $isSelected ? backgroundColor : white,
        borderRadius: 4,
        '& p': {
            color: $isSelected ? blueGrey[600] : blueGrey[900],
        },
        '&:hover': {
            backgroundColor: backgroundColor,
        },
    };
});
exports.FormFieldsContainer = styled_components_1.default.div({
    display: 'flex',
    flexDirection: 'column',
});
exports.TeamContainer = styled_components_1.default.div(function (_a) {
    var theme = _a.theme;
    var green = theme.foundational.green, semantic = theme.semantic;
    var borderColor = semantic ? semantic.primary[100] : green[100];
    return {
        width: '100%',
        padding: '32px 0',
        borderBottom: "1px solid ".concat(borderColor),
    };
});
exports.TeamFieldsContainer = styled_components_1.default.div(function (_a) {
    var $isTabletPortraitUp = _a.$isTabletPortraitUp;
    return {
        width: '100%',
        display: 'flex',
        flexDirection: $isTabletPortraitUp ? 'row' : 'column',
        alignItems: $isTabletPortraitUp ? 'flex-end' : 'flex-start',
        gap: 8,
    };
});
exports.CustomField = styled_components_1.default.div({
    width: '99%',
    '& label': {
        whiteSpace: 'break-spaces',
        textAlign: 'left',
    },
});
