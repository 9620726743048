"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateTeamDialog = exports.DELETE_TEAM_ERROR_MESSAGE = exports.UPDATE_TEAM_ERROR_MESSAGE = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var ds_ui_components_1 = require("@la/ds-ui-components");
var services_1 = require("@la/services");
var utilities_1 = require("@la/utilities");
var ErrorCard_1 = require("../../../../../../components/ErrorCard/ErrorCard");
var CreateTeamForm_1 = require("../../CreateTeamForm/CreateTeamForm");
var S = require("./UpdateTeamDialog.styles");
exports.UPDATE_TEAM_ERROR_MESSAGE = 'There was an error updating your team. Please try updating again in a few seconds.';
exports.DELETE_TEAM_ERROR_MESSAGE = 'There was an error deleting your team. Please try deleting again in a few seconds.';
function UpdateTeamDialog(_a) {
    var divisionId = _a.divisionId, maxDivisionAgeGroup = _a.maxDivisionAgeGroup, hasDivisionsWithTeamSelected = _a.hasDivisionsWithTeamSelected, setHasDeleteTeamDivisionError = _a.setHasDeleteTeamDivisionError, onDeleteTeam = _a.onDeleteTeam, onUpdateTeam = _a.onUpdateTeam, team = _a.team, tournamentId = _a.tournamentId, trigger = _a.trigger, customFields = _a.customFields;
    var _b = (0, services_1.useUpdateTeamMutation)(), updateTeam = _b[0], _c = _b[1], isUpdateTeamLoading = _c.isLoading, updateTeamError = _c.error, reset = _c.reset;
    var siteId = (0, utilities_1.getSiteIdFromURL)();
    var _d = (0, react_1.useState)(), isOpen = _d[0], setIsOpen = _d[1];
    var admin1 = team.admin1, country = team.country, name = team.name, status = team.status, teamRepresentative = team.teamRepresentative;
    var teamFormId = 'update-team-form';
    var defaultLocationValues;
    if (country === 'USA' && admin1) {
        defaultLocationValues = { country: country, state: admin1 };
    }
    else if (country === 'CAN' && admin1) {
        defaultLocationValues = { country: country, province: admin1 };
    }
    else {
        defaultLocationValues = { country: country };
    }
    var defaultValues = __assign(__assign({}, defaultLocationValues), { teamName: name, organization: team.organization, ageGroup: team.ageGroup, city: team.city, repName: teamRepresentative === null || teamRepresentative === void 0 ? void 0 : teamRepresentative.name, repPhoneNumber: teamRepresentative === null || teamRepresentative === void 0 ? void 0 : teamRepresentative.phoneNumber, repEmail: teamRepresentative === null || teamRepresentative === void 0 ? void 0 : teamRepresentative.email, customTeamFields: customFields });
    /**
     * Resets the query state so that errors do not show when
     * reopening the modal.
     */
    (0, react_1.useEffect)(function () {
        if (!isOpen) {
            reset();
        }
    }, [isOpen, reset]);
    var handleUpdateTeam = function (formTeam) {
        var _a, _b;
        var teamName = formTeam.teamName, state = formTeam.state, province = formTeam.province, repName = formTeam.repName, repPhoneNumber = formTeam.repPhoneNumber, repEmail = formTeam.repEmail, customTeamFields = formTeam.customTeamFields, restTeam = __rest(formTeam, ["teamName", "state", "province", "repName", "repPhoneNumber", "repEmail", "customTeamFields"]);
        var updatedFormFields = customTeamFields.map(utilities_1.mapFormFieldToWorkflow);
        var allFormFields = (0, utilities_1.removeDuplicateFormFields)(updatedFormFields.concat((_a = team.formFields) !== null && _a !== void 0 ? _a : []));
        var formattedTeam = __assign(__assign({}, restTeam), { id: team.id, name: teamName, admin1: (_b = state !== null && state !== void 0 ? state : province) !== null && _b !== void 0 ? _b : '', status: team.status, metadata: {
                version: '1.0',
                teamRepresentatives: repName && repPhoneNumber && repEmail
                    ? [{ name: repName, phoneNumber: repPhoneNumber, email: repEmail }]
                    : undefined,
                ageGroup: restTeam.ageGroup,
                formFields: allFormFields,
            } });
        updateTeam({
            siteDomain: 'manager',
            programId: divisionId,
            team: formattedTeam,
            siteId: siteId.toString(),
        })
            .unwrap()
            .then(function (updatedTeam) {
            setIsOpen(false);
            onUpdateTeam(updatedTeam.id, updatedTeam.ageGroup, true);
        });
    };
    var handleDeleteTeam = function (deletedTeamId) {
        setIsOpen(false);
        onDeleteTeam(deletedTeamId, true);
    };
    return ((0, jsx_runtime_1.jsxs)(ds_ui_components_1.Modal, __assign({ open: isOpen, onOpenChange: setIsOpen, primaryAction: (0, jsx_runtime_1.jsx)(S.UpdateTeamButton, __assign({ form: teamFormId, loading: isUpdateTeamLoading, type: "submit" }, { children: "Update team" })), secondaryAction: (0, jsx_runtime_1.jsx)(DeleteTeamDialog, { hasDivisionsWithTeamSelected: hasDivisionsWithTeamSelected, setHasDeleteTeamDivisionError: setHasDeleteTeamDivisionError, onDeleteTeam: handleDeleteTeam, team: team, tournamentId: tournamentId, closeUpdateModal: function () { return setIsOpen(false); } }), tertiaryAction: (0, jsx_runtime_1.jsx)(ds_ui_components_1.ModalClose, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.ModalTertiaryActionButton, { children: "Cancel" }) }), title: "Update team", trigger: trigger ? (trigger) : ((0, jsx_runtime_1.jsx)(S.UpdateTeamTriggerButton, { ariaLabel: "Update team ".concat(name), disabled: status !== 'DRAFT', variant: "text", icon: (0, jsx_runtime_1.jsx)(ds_ui_components_1.PencilSquareIcon, { fill: "red", size: "large" }) })) }, { children: [updateTeamError ? ((0, jsx_runtime_1.jsx)(ErrorCard_1.ErrorCard, { message: exports.UPDATE_TEAM_ERROR_MESSAGE })) : null, (0, jsx_runtime_1.jsx)(CreateTeamForm_1.CreateTeamForm, { defaultValues: defaultValues, id: teamFormId, maxDivisionAgeGroup: maxDivisionAgeGroup, onSubmit: handleUpdateTeam, customFields: customFields, existingFields: team.formFields })] })));
}
exports.UpdateTeamDialog = UpdateTeamDialog;
function DeleteTeamDialog(_a) {
    var hasDivisionsWithTeamSelected = _a.hasDivisionsWithTeamSelected, onDeleteTeam = _a.onDeleteTeam, team = _a.team, tournamentId = _a.tournamentId, setHasDeleteTeamDivisionError = _a.setHasDeleteTeamDivisionError, closeUpdateModal = _a.closeUpdateModal;
    var _b = (0, react_1.useState)(), isOpen = _b[0], setIsOpen = _b[1];
    var _c = (0, services_1.useDeleteTeamMutation)(), deleteTeam = _c[0], _d = _c[1], isDeleteTeamLoading = _d.isLoading, deleteTeamError = _d.error, reset = _d.reset;
    var siteId = (0, utilities_1.getSiteIdFromURL)();
    /**
     * Resets the query state so that errors do not show when
     * reopening the modal.
     */
    (0, react_1.useEffect)(function () {
        if (!isOpen) {
            reset();
        }
    }, [isOpen, reset]);
    var handleDeleteTeam = function () {
        if (hasDivisionsWithTeamSelected) {
            setHasDeleteTeamDivisionError(true);
            setIsOpen(false);
            closeUpdateModal();
            return;
        }
        var id = team.id;
        deleteTeam({
            siteDomain: 'manager',
            siteId: siteId.toString(),
            programId: tournamentId,
            editURL: "/app/tournaments/".concat(tournamentId, "/registration"),
            teamId: id,
        })
            .unwrap()
            .then(function () {
            setIsOpen(false);
            onDeleteTeam(id);
        });
    };
    return ((0, jsx_runtime_1.jsxs)(ds_ui_components_1.Modal, __assign({ open: isOpen, onOpenChange: setIsOpen, title: "Delete team?", primaryAction: (0, jsx_runtime_1.jsx)(S.DeleteTeamButton, __assign({ loading: isDeleteTeamLoading, onClick: handleDeleteTeam }, { children: "Delete team" })), tertiaryAction: (0, jsx_runtime_1.jsx)(ds_ui_components_1.ModalClose, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.ModalSecondaryActionButton, { children: "Cancel" }) }), trigger: (0, jsx_runtime_1.jsx)(ds_ui_components_1.ModalSecondaryActionButton, { children: "Delete team" }) }, { children: [deleteTeamError ? ((0, jsx_runtime_1.jsx)(ErrorCard_1.ErrorCard, { message: exports.DELETE_TEAM_ERROR_MESSAGE })) : null, (0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ size: "large", variant: "ui" }, { children: "Are you sure you want to delete this team altogether?" }))] })));
}
