import { DragEvent } from 'react';
import { getMemberTileId, MemberTile } from '../MemberTile/MemberTile';
import { SelectedMembersOptions } from '../SelectedMembersOptions/SelectedMembersOptions';
import { Member, MemberState } from '../types/member';
import * as S from './MemberOption.styles';

export type MemberOptionProps = {
  /**
   * Whether or not to display the placeholder for the content where the option
   * originated from. Is a separate property from `isDragging` because we do not
   * want the placeholder to be a part of the drag image.
   */
  hideOverflow?: boolean;
  /**
   * Whether or not the option is being dragged.
   */
  isDragging?: boolean;
  /**
   * Whether or not the dragging action was triggered by this option.
   */
  isDragOrigin?: boolean;
  /**
   * The member whose details should be displayed. Includes the selected state.
   */
  member: MemberState;
  /**
   * Triggered when the option is done being dragged.
   */
  onDragEnd?: () => void;
  /**
   * Triggered when the option starts being dragged.
   */
  onDragStart?: (e: DragEvent<HTMLLIElement>, userId: number) => void;
  /**
   * Triggered when the remove icon button is clicked. Will not render the remove icon
   * button if not defined.
   * @param member The member to be removed.
   */
  onRemoveClick?: (member: Member) => void;
  /**
   * Triggered when the checkbox is selected. Does not render certain
   * styles when undefined.
   * @param selected Whether or not the option is selected.
   * @param member The member whose selection state is changing.
   */
  onSelectChange?: (selected: boolean, member: Member) => void;
  /**
   * All selected members. This is used to properly set the drag image that
   * appears when dragging starts.
   */
  selectedMembers: MemberState[];
  isDisabled: boolean;
};

/* MemberOption */
export function MemberOption({
  hideOverflow = false,
  isDragging = false,
  isDragOrigin = false,
  member,
  onDragEnd,
  onDragStart,
  onRemoveClick,
  onSelectChange,
  selectedMembers,
  isDisabled,
}: Readonly<MemberOptionProps>) {
  const { selected } = member;

  const onMemberOptionDragStart = (e: DragEvent<HTMLLIElement>): void => {
    if (onDragStart && !isDisabled) {
      onDragStart(e, member.userId);
    }
  };

  return (
    <S.MemberOption
      $hideOverflow={hideOverflow}
      $selectable={!!onSelectChange}
      $selected={selected}
      draggable={!!(selected && onDragEnd && onDragStart && !isDisabled)}
      key={getMemberTileId(member.userId, member.type)}
      onDragEnd={onDragEnd}
      onDragStart={onMemberOptionDragStart}
    >
      {!isDragging || hideOverflow ? (
        <MemberTile
          {...member}
          onRemoveClick={onRemoveClick}
          onSelectChange={onSelectChange}
          selected={selected}
          isDisabled={isDisabled}
        />
      ) : null}
      {/* We render all the members so that the drag image that is displayed contains all the 
      members rather than just the originating drag member */}
      {isDragOrigin && selected && isDragging ? (
        <SelectedMembersOptions members={selectedMembers} />
      ) : null}
      {selected && hideOverflow ? <S.DragOverlay /> : null}
    </S.MemberOption>
  );
}
/* */
