import { DragEvent } from 'react';
import { Typography } from '@la/ds-ui-components';
import { TeamRoster } from 'redux/services/rosterManagementApi';
import { useGetMembersState } from '../hooks/useGetMembersState';
import { RolloverTeam, RolloverTeamType } from './RolloverTeam/RolloverTeam';
import * as S from './RolloverDropzone.styles';

export const HOVERED_STATE_TEXT = 'Drop players and staff here.';

export type DestinationRolloverDropzoneProps = {
  /**
   * Whether or not to display the hovered state of the dropzone. Should use when
   * a member is currently being dragged.
   */
  showHoveredState?: boolean;
  /**
   * Team roster the user is rolling members from. Cannot be undefined.
   */
  teamRoster: TeamRoster;
  /**
   * Handler called when one or more staff or player are dropped in the destination zone.
   */
  onRollover: (teamRoster: TeamRoster) => void;
};

/* DestinationRolloverDropzone */
export function DestinationRolloverDropzone({
  showHoveredState,
  teamRoster,
  onRollover,
}: Readonly<DestinationRolloverDropzoneProps>) {
  const { membersState, selectedMembers, setMembersState } = useGetMembersState(
    { teamRoster }
  );
  const onDragOver = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  };

  const onDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();

    const teamRoster: TeamRoster = JSON.parse(
      e.dataTransfer.getData('application/json')
    );

    onRollover(teamRoster);
  };

  return (
    <S.Dropzone
      $hasSelectedTeam
      $isHovered={showHoveredState}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      {showHoveredState ? (
        <Typography size="large" variant="ui" weight="bold">
          {HOVERED_STATE_TEXT}
        </Typography>
      ) : (
        <RolloverTeam
          teamRoster={teamRoster}
          type={RolloverTeamType.Destination}
          includedRoster={{ players: [], staff: [], team: teamRoster.team }}
          membersState={membersState}
          setMembersState={setMembersState}
          selectedMembers={selectedMembers}
        />
      )}
    </S.Dropzone>
  );
}
/* */
