"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeamSelectionSection = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var ds_ui_components_1 = require("@la/ds-ui-components");
var services_1 = require("@la/services");
var utilities_1 = require("@la/utilities");
var hooks_1 = require("../../lib/hooks");
var breakpoints_1 = require("../../lib/media-queries/breakpoints");
var UpdateTeamDialog_1 = require("../../tournaments/registration/Wizard/components/DivisionCard/DivisionCardTeamSelectionSection/UpdateTeamDialog");
var capacity_1 = require("../../tournaments/registration/Wizard/components/DivisionCard/utils/capacity");
// import { useTeamsWithFields } from 'domains/Tournaments/Registration/hooks/useTeamsWithFields';
var capacity_2 = require("./utils/capacity");
var validation_1 = require("./utils/validation");
var S = require("./TeamSelectionSection.styles");
var createNewTeamOption = {
    name: '+ Create team',
    value: 'create-team',
    hasCheckbox: false,
};
var mapTeamsToMultiSelectOptions = function (teams, divisionAtCapacity, registeredTeams, selectedTeams) {
    return teams.map(function (team) {
        var _a;
        var isRegistered = registeredTeams.includes(team.id);
        var isSelected = selectedTeams.includes(team.id);
        var disabled = divisionAtCapacity || isRegistered || isSelected;
        var disabledReason;
        if (divisionAtCapacity) {
            disabledReason = capacity_2.AT_CAPACITY_DISABLED_REASON;
        }
        return {
            name: team.name,
            value: team.id,
            subtitle: (_a = team.teamRepresentative) === null || _a === void 0 ? void 0 : _a.name,
            badgeText: isRegistered ? 'Registered' : undefined,
            disabled: disabled,
            disabledReason: disabledReason,
            checkboxState: isSelected ? true : undefined,
        };
    });
};
var TeamSelectionSection = function (_a) {
    var teams = _a.teams, openCreateTeamDialog = _a.openCreateTeamDialog, onSelectTeam = _a.onSelectTeam, onAddTeamSelect = _a.onAddTeamSelect, onUpdateTeam = _a.onUpdateTeam, onDeleteTeam = _a.onDeleteTeam, onDiscardTeamSelect = _a.onDiscardTeamSelect, onClear = _a.onClear, division = _a.division, cardState = _a.cardState, teamsList = _a.teamsList, maxDivisionAgeGroup = _a.maxDivisionAgeGroup, tournamentId = _a.tournamentId, setHasDeleteTeamDivisionError = _a.setHasDeleteTeamDivisionError, selectError = _a.selectError, setSelectError = _a.setSelectError, selectRef = _a.selectRef;
    var teamSelections = cardState.teamSelections;
    var numberOfSpotsLeft = (0, capacity_1.getNumberOfSpotsLeft)(division);
    var siteId = (0, services_1.useAppSelector)(services_1.getSiteId);
    var isTabletPortaitUp = (0, hooks_1.useMediaQuery)(breakpoints_1.breakpointQueries.tabletPortraitUp);
    var teamRepLabel = (0, services_1.useGetTeamRepLabelQuery)({
        siteId: siteId !== null && siteId !== void 0 ? siteId : '',
    }).data;
    var registeredTeams = (0, react_1.useMemo)(function () {
        var _a;
        var teams = (_a = division.registrationsForUser) === null || _a === void 0 ? void 0 : _a.team.filter(function (registration) { return registration.registrationStatus === 'REGISTERED'; }).map(function (registration) { return registration.teamId; });
        return teams !== null && teams !== void 0 ? teams : [];
    }, [division]);
    var _b = (0, react_1.useState)(function () {
        var selectedIds = teamSelections.map(function (selection) { return selection.teamId; });
        return mapTeamsToMultiSelectOptions(teamsList, (0, capacity_2.isDivisionAtCapacity)(division, teamSelections), registeredTeams, selectedIds !== null && selectedIds !== void 0 ? selectedIds : []);
    }), selectedTeams = _b[0], setSelectedTeams = _b[1];
    var hasMoreOptionsThanSpotsAvailable = numberOfSpotsLeft !== undefined &&
        selectedTeams.filter(function (selectedTeam) {
            return !selectedTeam.disabled ||
                selectedTeam.disabledReason === capacity_2.AT_CAPACITY_DISABLED_REASON;
        }).length > numberOfSpotsLeft;
    var multiSelectHelperText = hasMoreOptionsThanSpotsAvailable ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["This division only has capacity for\u00A0", (0, jsx_runtime_1.jsx)("strong", { children: numberOfSpotsLeft }), "\u00A0team", numberOfSpotsLeft !== 1 ? 's' : '', "."] })) : undefined;
    (0, react_1.useEffect)(function () {
        if (!selectError) {
            var selectedIds = teamSelections.map(function (selection) { return selection.teamId; });
            setSelectedTeams(mapTeamsToMultiSelectOptions(teamsList, (0, capacity_2.isDivisionAtCapacity)(division, teamSelections), registeredTeams, selectedIds !== null && selectedIds !== void 0 ? selectedIds : []));
        }
    }, [division, registeredTeams, teamSelections, teamsList, selectError]);
    var divisionId = division.id;
    var subdomain = (0, utilities_1.getLAHostnameParts)().subdomain;
    var data = (0, services_1.useGetUserRegistrationsQuery)({
        siteDomain: subdomain,
        programId: division.id,
    }).data;
    var toggleAtCapacityOptions = function (updatedTeamOptions) {
        var selectedTeamsCount = updatedTeamOptions.filter(function (team) { return team.checkboxState; }).length;
        if (numberOfSpotsLeft !== undefined) {
            if (selectedTeamsCount >= numberOfSpotsLeft) {
                return updatedTeamOptions.map(function (teamOption) {
                    return (0, capacity_2.disableTeamForAtCapacity)(teamOption, teamSelections);
                });
            }
            return updatedTeamOptions.map(capacity_2.enableTeamForAtCapacity);
        }
        return updatedTeamOptions;
    };
    var handleSelectTeam = function (teamId) {
        if (teamId === createNewTeamOption.value) {
            openCreateTeamDialog(teamSelections.length);
            return;
        }
        setSelectedTeams(function (teams) {
            return toggleAtCapacityOptions(teams.map(function (team) {
                if (team.value === teamId) {
                    var isSelected = team.isSelected;
                    return __assign(__assign({}, team), { isSelected: !isSelected, shouldRender: !isSelected, checkboxState: !isSelected });
                }
                return team;
            }));
        });
    };
    var handleSelectAll = function () {
        if (selectedTeams.every(function (team) { return team.disabled; })) {
            return;
        }
        setSelectedTeams(function (teams) {
            var hasSelected = teams.some(function (team) { return team.isSelected; });
            return teams.map(function (team) { return (__assign(__assign({}, team), { isSelected: hasSelected || team.disabled ? false : true, shouldRender: hasSelected || team.disabled ? false : true, checkboxState: team.disabled ? team.checkboxState : undefined })); });
        });
    };
    var handleTagDismiss = function (teamId) {
        if (!teamId) {
            return;
        }
        setSelectedTeams(function (teams) {
            return toggleAtCapacityOptions(teams.map(function (team) {
                if (team.value === teamId) {
                    var isSelected = team.isSelected;
                    return __assign(__assign({}, team), { isSelected: !isSelected, shouldRender: !isSelected, checkboxState: !isSelected });
                }
                return team;
            }));
        });
    };
    var handleAddTeams = function () {
        setSelectError();
        var selectedIds = selectedTeams
            .filter(function (team) { return team.isSelected; })
            .map(function (team) { return team.value; });
        selectedIds.forEach(function (teamId, index) {
            onAddTeamSelect();
            onSelectTeam(index + teamSelections.length, teamId);
        });
        setSelectedTeams(function (teams) {
            return teams.map(function (team) {
                if (selectedIds.includes(team.value)) {
                    return __assign(__assign({}, team), { isSelected: false, shouldRender: false, disabled: true, checkboxState: true });
                }
                return team;
            });
        });
    };
    var handleDiscardTeam = function (selectIndex) {
        var teamId = teamSelections[selectIndex].teamId;
        setSelectedTeams(function (teams) {
            return teams.map(function (team) {
                if (team.value !== teamId) {
                    return team;
                }
                return __assign(__assign({}, team), { disabled: false, isSelected: false, checkboxState: false });
            });
        });
        onDiscardTeamSelect(selectIndex);
        if (teamSelections.length === 1) {
            onClear();
        }
    };
    var isAddTeamsDisabled = selectedTeams.every(function (team) { return !team.isSelected; });
    var hasSelected = selectedTeams.some(function (team) { return team.isSelected; });
    return ((0, jsx_runtime_1.jsxs)(S.TeamSelectionContainer, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "headline", size: "xs" }, { children: "Add your teams" })), (0, jsx_runtime_1.jsxs)(S.SelectSection, { children: [(0, jsx_runtime_1.jsx)(S.SelectContainer, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.MultiSelect, { disabledSelectAll: hasMoreOptionsThanSpotsAvailable, helperText: multiSelectHelperText, options: selectedTeams, placeholder: "Select your teams", id: "team-selection-multiselect", selectAllText: "Select all", displayClearButton: false, primarySubtitle: isTabletPortaitUp
                                ? 'Team name'
                                : "Team name / ".concat(teamRepLabel, " name"), secondarySubtitle: isTabletPortaitUp ? "".concat(teamRepLabel, " name") : undefined, bottomAction: __assign(__assign({}, createNewTeamOption), { disabled: numberOfSpotsLeft !== undefined &&
                                    selectedTeams.filter(function (selectedTeam) { return selectedTeam.isSelected; })
                                        .length >= numberOfSpotsLeft }), onSelectValue: handleSelectTeam, onSelectAll: handleSelectAll, onSelectClear: function () { }, onTagDismiss: handleTagDismiss, hasError: selectError, errorMessage: hasSelected
                                ? 'Please Select "Add teams" before saving division'
                                : 'Please add teams before saving division', ref: selectRef }) }), (0, jsx_runtime_1.jsx)(S.AddTeamsButtonContainer, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Button, __assign({ variant: "outline", size: "large", disabled: isAddTeamsDisabled, onClick: handleAddTeams, width: "100%" }, { children: "Add teams" })) })] }), teamSelections.length ? ((0, jsx_runtime_1.jsx)(S.AddedTeamsContainer, { children: teamSelections.map(function (selection, i) {
                    var _a;
                    var team = teams.find(function (team) { return team.id === selection.teamId; });
                    var hasDivisionsWithTeamSelected = (_a = data === null || data === void 0 ? void 0 : data.userRegistrations.some(function (registration) { return registration.teamId === (team === null || team === void 0 ? void 0 : team.id); })) !== null && _a !== void 0 ? _a : false;
                    if (!team) {
                        return null;
                    }
                    return ((0, jsx_runtime_1.jsx)(AddedTeam, { team: team, isLast: i === teamSelections.length - 1, selectIndex: i, divisionId: divisionId, maxDivisionAgeGroup: maxDivisionAgeGroup, hasDivisionsWithTeamSelected: hasDivisionsWithTeamSelected, tournamentId: tournamentId, onUpdateTeam: onUpdateTeam, onDeleteTeam: onDeleteTeam, setHasDeleteTeamDivisionError: setHasDeleteTeamDivisionError, onDiscard: handleDiscardTeam, customFields: division.customTeamFields }, team.id));
                }) })) : null] }));
};
exports.TeamSelectionSection = TeamSelectionSection;
var AddedTeam = function (_a) {
    var _b;
    var team = _a.team, isLast = _a.isLast, selectIndex = _a.selectIndex, divisionId = _a.divisionId, maxDivisionAgeGroup = _a.maxDivisionAgeGroup, hasDivisionsWithTeamSelected = _a.hasDivisionsWithTeamSelected, tournamentId = _a.tournamentId, onUpdateTeam = _a.onUpdateTeam, onDeleteTeam = _a.onDeleteTeam, setHasDeleteTeamDivisionError = _a.setHasDeleteTeamDivisionError, onDiscard = _a.onDiscard, customFields = _a.customFields;
    var showIncompleteFieldsWarning = (0, validation_1.hasIncompleteRequiredFields)(customFields, team);
    return ((0, jsx_runtime_1.jsxs)(S.AddedTeam, __assign({ "$isLast": isLast }, { children: [(0, jsx_runtime_1.jsxs)(S.AddedTeamNameContainer, { children: [(0, jsx_runtime_1.jsx)(S.AddedTeamName, __assign({ variant: "ui", size: "large", weight: "bold" }, { children: team.name })), (0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "medium", italic: true }, { children: (_b = team.teamRepresentative) === null || _b === void 0 ? void 0 : _b.name })), showIncompleteFieldsWarning ? ((0, jsx_runtime_1.jsx)(ds_ui_components_1.WarningIcon, { variant: "filled", size: "large", fill: "#F98A28" })) : null] }), (0, jsx_runtime_1.jsxs)(S.ActionIconsContainer, { children: [(0, jsx_runtime_1.jsx)(UpdateTeamDialog_1.UpdateTeamDialog, { divisionId: divisionId, maxDivisionAgeGroup: maxDivisionAgeGroup, hasDivisionsWithTeamSelected: hasDivisionsWithTeamSelected, onDeleteTeam: onDeleteTeam, onUpdateTeam: onUpdateTeam, team: team, tournamentId: tournamentId, setHasDeleteTeamDivisionError: setHasDeleteTeamDivisionError, trigger: (0, jsx_runtime_1.jsx)(S.DiscardTeamButtonContainer, __assign({ "$disabled": false }, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.PencilSquareIcon, { size: "large", fill: "var(--secondary-500)" }) })), customFields: customFields }), (0, jsx_runtime_1.jsx)(S.DiscardTeamButtonContainer, __assign({ "$disabled": false, onClick: function () {
                            onDiscard(selectIndex);
                        } }, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.CloseCircleIcon, { variant: "filled", size: "large", fill: 'var(--secondary-500)' }) }))] })] })));
};
