import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Modal,
  Typography,
  Form,
  TextInput,
} from '@la/ds-ui-components';
import { StaffInvitation } from '@la/types';
import { InputGroupContainer } from 'components/RegistrationInfoCard/RegistrationInfoCard.styles';
import { InviteStaffContent } from '../InvitePlayerStaffModals';
import * as S from './CreateStaffModal.styles';

type CreateStaffModalProps = {
  onSubmit: (values: CreateStaffFormFields) => void;
  staffInviteLinks?: StaffInvitation[];
  isOpen: boolean;
  onOpenChange: () => void;
};

export type CreateStaffFormFields = {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
};

const FIELD_REQUIRED_ERROR = 'This field is required.';
const CREATE_STAFF_FORM_ID = 'create-staff-form';

export const CreateStaffModal = ({
  onSubmit,
  staffInviteLinks,
  isOpen,
  onOpenChange,
}: CreateStaffModalProps) => {
  const [roleId, setRoleId] = useState('');

  const createStaffFormValidation = yup.object<CreateStaffFormFields>({
    firstName: yup.string().trim().required(FIELD_REQUIRED_ERROR),
    lastName: yup.string().trim().required(FIELD_REQUIRED_ERROR),
    email: yup.string().trim().required(FIELD_REQUIRED_ERROR),
  });

  const defaultValues: CreateStaffFormFields = {
    firstName: '',
    lastName: '',
    email: '',
    roleId: '',
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(createStaffFormValidation),
  });

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = methods;

  const handleSelectOption = (roleId: string) => {
    setRoleId(roleId);
  };

  return (
    <Modal
      open={isOpen}
      onOpenChange={onOpenChange}
      title="Add a staff member"
      primaryAction={
        <Button type="submit" form={CREATE_STAFF_FORM_ID} disabled={!roleId}>
          Add staff
        </Button>
      }
      tertiaryAction={
        <Button variant="text" size="medium">
          Cancel
        </Button>
      }
    >
      <Typography variant="ui" size="xl" weight="bold">
        Add a staff member to your roster.
      </Typography>
      <FormProvider {...methods}>
        <Form
          id={CREATE_STAFF_FORM_ID}
          noValidate
          onSubmit={handleSubmit((values) => {
            if (!isSubmitting) {
              onSubmit({ ...values, roleId });
            }
          })}
        >
          <S.CreateStaffFormContainer>
            <InputGroupContainer>
              <TextInput
                {...register('firstName')}
                errorMessage={errors.firstName?.message}
                hasError={!!errors.firstName}
                id="firstName"
                label="Staff member first name"
                required
              />

              <TextInput
                {...register('lastName')}
                errorMessage={errors.lastName?.message}
                hasError={!!errors.lastName}
                id="lastName"
                label="Staff member last name"
                required
              />
            </InputGroupContainer>

            <TextInput
              {...register('email')}
              errorMessage={errors.email?.message}
              hasError={!!errors.email}
              id="email"
              label="Email address"
              required
            />
          </S.CreateStaffFormContainer>
          <S.CreateStaffFormContainer>
            <Typography variant="ui" size="xl">
              Grab an invite link that you can share with your newly added staff
              members!
            </Typography>
            <InviteStaffContent
              onSelectOption={handleSelectOption}
              staffInvites={staffInviteLinks ?? []}
            />
          </S.CreateStaffFormContainer>
        </Form>
      </FormProvider>
    </Modal>
  );
};
