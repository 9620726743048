import { DragEvent, useState } from 'react';
import { Button, Typography } from '@la/ds-ui-components';
import { TeamRoster } from 'redux/services/rosterManagementApi';
import { TeamDetails } from 'domains/RosterManagement/RosterRollover/TeamDetails/TeamDetails';
import {
  UseGetMembersStatePayload,
  useGetMembersState,
} from '../../hooks/useGetMembersState';
import { MemberOption } from './MemberOption/MemberOption';
import {
  Member,
  MemberType,
  PlayerMemberState,
  StaffMemberState,
} from './types/member';
import * as S from './RolloverTeam.styles';

export const CHANGE_TEAM_LABEL = 'Change team';

export type RolloverTeamState = {
  players: PlayerMemberState[];
  staff: StaffMemberState[];
};

export enum RolloverTeamType {
  Destination = 'Destination',
  Origin = 'Origin',
}

export type RolloverTeamProps = {
  /**
   * Triggered when `showTeamSelectionOption` is `true` and the change
   * team button is clicked.
   */
  onTeamSelectionClick?: () => void;
  /**
   * The team to render players and staff for.
   */
  teamRoster: TeamRoster;
  /**
   * The type of team (ORIGIN or DESTINATION). Determines the text that
   * appears when there are no members.
   */
  type: RolloverTeamType;
  includedRoster: TeamRoster;
  onSelectedStateChange?: (state: RolloverTeamState) => void;
} & Partial<UseGetMembersStatePayload>;

/* RolloverTeam */
export function RolloverTeam({
  onTeamSelectionClick,
  teamRoster,
  type,
  includedRoster,
  onSelectedStateChange,
  membersState: rolloverMembersState,
  setMembersState: setRolloverMembersState,
  selectedMembers: rolloverSelectedMembers,
}: Readonly<RolloverTeamProps>) {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [originDragTile, setOriginDragTile] = useState<number>();
  const [hideOverflow, setHideOverflow] = useState<boolean>(false);

  const { membersState, setMembersState, selectedMembers } = useGetMembersState(
    { teamRoster }
  );

  const state = rolloverMembersState ?? membersState;
  const setState = setRolloverMembersState ?? setMembersState;
  const selected = rolloverSelectedMembers ?? selectedMembers;

  const onDragEnd = (): void => {
    setIsDragging(false);
    setOriginDragTile(undefined);
    setHideOverflow(false);
  };

  const onDragStart = (e: DragEvent<HTMLLIElement>, userId: number): void => {
    const selectedMembersState = {
      players: state.players.filter((player) => player.selected),
      staff: state.staff.filter((staff) => staff.selected),
    };

    if (e.dataTransfer) {
      e.dataTransfer.dropEffect = 'copy';
      e.dataTransfer.setData(
        'application/json',
        JSON.stringify(selectedMembersState)
      );
      setIsDragging(true);
      setOriginDragTile(userId);
      setTimeout(() => {
        setHideOverflow(true);
      }, 1);
    }
  };

  const onSelectChange = (selected: boolean, member: Member): void => {
    if (member.type === MemberType.Player) {
      const newState = {
        players: state.players.map((m) => {
          if (m.userId === member.userId) {
            return { ...m, selected };
          }
          return m;
        }),
        staff: state.staff,
      };
      setState(newState);
      if (onSelectedStateChange) {
        onSelectedStateChange({
          players: newState.players.filter((player) => player.selected),
          staff: newState.staff.filter((staff) => staff.selected),
        });
      }
    } else {
      const newState = {
        players: state.players,
        staff: state.staff.map((m) => {
          if (m.userId === member.userId) {
            return { ...m, selected };
          }
          return m;
        }),
      };
      setState(newState);
      if (onSelectedStateChange) {
        onSelectedStateChange({
          players: newState.players.filter((player) => player.selected),
          staff: newState.staff.filter((staff) => staff.selected),
        });
      }
    }
  };

  const baseMemberOptionProps = {
    hideOverflow,
    isDragging,
    selectedMembers: selected,
  };
  const memberOptionProps =
    type === RolloverTeamType.Origin
      ? { ...baseMemberOptionProps, onDragEnd, onDragStart, onSelectChange }
      : { ...baseMemberOptionProps, onRemoveClick: () => {} };

  return (
    <S.RolloverTeam>
      <S.SummaryName>
        <Typography size="small" variant="headline">
          {teamRoster.team.name}
        </Typography>
        {onTeamSelectionClick ? (
          <S.ChangeTeamButton>
            <Button onClick={onTeamSelectionClick} size="small" variant="text">
              {CHANGE_TEAM_LABEL}
            </Button>
          </S.ChangeTeamButton>
        ) : null}
      </S.SummaryName>
      <TeamDetails team={teamRoster.team} />
      <S.Members>
        <S.MembersList data-testid={getMemberSectionId('player', type)}>
          <S.MembersTitle>Players</S.MembersTitle>
          {state.players.length > 0 ? (
            state.players.map((player) => {
              return (
                <MemberOption
                  {...memberOptionProps}
                  key={`rollover-team-player-${player.userId}`}
                  isDragOrigin={player.userId === originDragTile}
                  member={player}
                  isDisabled={includedRoster.players
                    .map((p) => p.userId)
                    .includes(player.userId)}
                />
              );
            })
          ) : (
            <S.NoMembersText>
              There are no players on this team. <br />
              {getNoMembersText('player', type)}
            </S.NoMembersText>
          )}
        </S.MembersList>
        <S.MembersList data-testid={getMemberSectionId('staff', type)}>
          <S.MembersTitle>Staff</S.MembersTitle>
          {state.staff.length > 0 ? (
            state.staff.map((staff) => {
              return (
                <MemberOption
                  {...memberOptionProps}
                  key={`rollover-team-staff-${staff.userId}`}
                  isDragOrigin={staff.userId === originDragTile}
                  member={staff}
                  isDisabled={includedRoster.staff
                    .map((s) => s.userId)
                    .includes(staff.userId)}
                />
              );
            })
          ) : (
            <S.NoMembersText>
              There are no staff on this team. <br />
              {getNoMembersText('staff', type)}
            </S.NoMembersText>
          )}
        </S.MembersList>
      </S.Members>
    </S.RolloverTeam>
  );
}

export function getMemberSectionId(
  memberType: 'player' | 'staff',
  type: RolloverTeamType
): string {
  const memberTypeName = memberType === 'player' ? 'players' : 'staff';
  const typeName = type === RolloverTeamType.Origin ? 'origin' : 'destination';
  return `${memberTypeName}-${typeName}`;
}

export function getNoMembersText(
  memberType: 'player' | 'staff',
  type: RolloverTeamType
): string {
  return type === RolloverTeamType.Destination
    ? `Start adding ${memberType === 'player' ? 'players' : 'staff'} now!`
    : 'Please select another team.';
}
