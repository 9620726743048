import { useState } from 'react';
import { Button, Modal, Select, Typography } from '@la/ds-ui-components';
import { StaffRole } from '@la/types';
import { Registrant, TeamRoster } from 'redux/services/rosterManagementApi';
import * as S from './AssignStaffRolesModal.styles';

export type AssignStaffRolesModalProps = {
  open: boolean;
  onOpenChange: () => void;
  staffRoster: Registrant[];
  staffRoles: StaffRole[];
  setNewRoster: React.Dispatch<React.SetStateAction<TeamRoster>>;
};

export const SELECT_ROLE_ERROR = 'Select a role for the rollover staff';

export const AssignStaffRolesModal = ({
  open,
  onOpenChange,
  staffRoster,
  staffRoles,
  setNewRoster,
}: AssignStaffRolesModalProps) => {
  const [showError, setShowError] = useState(false);
  // TODO: For now is unclear how we will pass the registrant's role.
  // We can update value to be the roleId once we have a contract
  const rolesOptions = staffRoles.map(({ role }) => ({
    label: role,
    value: role,
  }));

  const onSaveRoleAssignments = () => {
    const hasEmptyRole = staffRoster.some((staff) => !Boolean(staff.role));

    if (hasEmptyRole) {
      setShowError(true);
      return;
    }
    onOpenChange();
  };

  const handleRoleSelect = (role: string, staffId: number) => {
    setNewRoster((roster) => ({
      ...roster,
      staff: roster.staff.map((staff) => {
        if (staff.userId !== staffId) {
          return staff;
        }
        return {
          ...staff,
          role,
        };
      }),
    }));
  };

  return (
    <Modal
      title="Assign staff roles"
      open={open}
      onOpenChange={onOpenChange}
      primaryAction={
        <Button size="large" onClick={onSaveRoleAssignments}>
          Save role assignments
        </Button>
      }
      tertiaryAction={
        <Button variant="text" onClick={onOpenChange}>
          Discard changes
        </Button>
      }
      size="small"
    >
      <Typography variant="ui" size="large" weight="bold">
        Assign staff roles for your new roster.
      </Typography>
      <S.StaffRosterContainer>
        {staffRoster.map((staff) => (
          <S.StaffContainer key={staff.userId}>
            <Typography variant="ui" size="large">
              {staff.name}
            </Typography>
            <S.StaffSelectContainer>
              <Select
                options={rolesOptions}
                placeholder="Select a role"
                id={`${staff.userId}-select-role`}
                value={staff.role}
                width="100%"
                hasError={showError && !staff.role}
                errorMessage={SELECT_ROLE_ERROR}
                onChange={(role) => handleRoleSelect(role, staff.userId)}
              />
            </S.StaffSelectContainer>
          </S.StaffContainer>
        ))}
      </S.StaffRosterContainer>
    </Modal>
  );
};
